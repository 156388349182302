@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap);
/*--------------------------------

LadiIcons Web Font
Generated using nucleoapp.com

-------------------------------- */

@font-face {
  font-family: 'LadiIcons';
  src: url(/static/media/LadiIcons.21846d2d.eot);
  src: url(/static/media/LadiIcons.21846d2d.eot) format('embedded-opentype'), url(/static/media/LadiIcons.a53b4922.woff2) format('woff2'), url(/static/media/LadiIcons.bfa2c0cd.woff) format('woff'), url(/static/media/LadiIcons.c3264611.ttf) format('truetype'), url(/static/media/LadiIcons.07cc8200.svg) format('svg');
  font-weight: normal;
  font-style: normal;
}

/*------------------------
	base class definition
-------------------------*/

.ladi-icon {
  display: inline-block;
  font: normal normal normal 1em/1 'LadiIcons';
  speak: none;
  text-transform: none;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*------------------------
  change icon size
-------------------------*/

/* relative units */

.ladi-icon-sm {
  font-size: 0.8em;
}

.ladi-icon-lg {
  font-size: 1.2em;
}

/* absolute units */

.ladi-icon-16 {
  font-size: 16px;
}

.ladi-icon-32 {
  font-size: 32px;
}

/*----------------------------------
  add a square/circle background
-----------------------------------*/

.ladi-icon-bg-square, .ladi-icon-bg-circle {
  padding: 0.35em;
  background-color: #eee;
}

.ladi-icon-bg-circle {
  border-radius: 50%;
}

/*------------------------------------
  use icons as list item markers
-------------------------------------*/

.ladi-icon-ul {
  padding-left: 0;
  list-style-type: none;
}

.ladi-icon-ul>li {
  display: flex;
  align-items: flex-start;
  line-height: 1.4;
}

.ladi-icon-ul>li>.ladi-icon {
  margin-right: 0.4em;
  line-height: inherit;
}

/*------------------------
  spinning icons
-------------------------*/

.ladi-icon-is-spinning {
  -webkit-animation: ladi-icon-spin 2s infinite linear;
  animation: ladi-icon-spin 2s infinite linear;
}

@-webkit-keyframes ladi-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes ladi-icon-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*------------------------
  rotated/flipped icons
-------------------------*/

.ladi-icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.ladi-icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.ladi-icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.ladi-icon-flip-y {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0);
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.ladi-icon-flip-x {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

/*------------------------
	icons
-------------------------*/

.icon-home::before {
  content: "\ea03";
}

.icon-dots::before {
  content: "\ea04";
}

.icon-c-add::before {
  content: "\ea05";
}

.icon-c-delete::before {
  content: "\eaab";
}

.icon-img::before {
  content: "\ea06";
}

.icon-button::before {
  content: "\ea07";
}

.icon-text::before {
  content: "\ea08";
}

.icon-paragraph::before {
  content: "\ea09";
}

.icon-list-bullet::before {
  content: "\ea0a";
}

.icon-shape-line::before {
  content: "\ea0b";
}

.icon-shape-rectangle::before {
  content: "\ea0c";
}

.icon-shape-star::before {
  content: "\ea0d";
}

.icon-html::before {
  content: "\ea0e";
}

.icon-video-player::before {
  content: "\ea0f";
}

.icon-slider::before {
  content: "\ea10";
}

.icon-gallery::before {
  content: "\ea11";
}

.icon-form::before {
  content: "\ea12";
}

.icon-time-clock::before {
  content: "\ea13";
}

.icon-section::before {
  content: "\ea14";
}

.icon-popup::before {
  content: "\ea15";
}

.icon-app-store::before {
  content: "\ea16";
}

.icon-triangle-down::before {
  content: "\ea17";
}

.icon-triangle-up::before {
  content: "\ea18";
}

.icon-publish::before {
  content: "\ea19";
}

.icon-cloud-download::before {
  content: "\ea1a";
}

.icon-preview::before {
  content: "\ea1b";
}

.icon-b-preview::before {
  content: "\ea1c";
}

.icon-undo::before {
  content: "\ea1d";
}

.icon-redo::before {
  content: "\ea1e";
}

.icon-settings::before {
  content: "\ea1f";
}

.icon-history::before {
  content: "\ea20";
}

.icon-fonts::before {
  content: "\ea21";
}

.icon-align-justify::before {
  content: "\ea22";
}

.icon-align-center::before {
  content: "\ea23";
}

.icon-align-left-2::before {
  content: "\ea24";
}

.icon-mobile::before {
  content: "\ea25";
}

.icon-pc::before {
  content: "\ea26";
}

.icon-back-arrow::before {
  content: "\ea27";
}

.icon-e-remove::before {
  content: "\ea28";
}

.icon-data-upload::before {
  content: "\ea29";
}

.icon-dots-to-close::before {
  content: "\ea2a";
}

.icon-floppy-disk::before {
  content: "\ea2b";
}

.icon-cross-vertical::before {
  content: "\ea2c";
}

.icon-hyperlink::before {
  content: "\ea2d";
}

.icon-link-broken::before {
  content: "\ea2e";
}

.icon-text-color::before {
  content: "\ea2f";
}

.icon-bold::before {
  content: "\ea30";
}

.icon-italic::before {
  content: "\ea31";
}

.icon-underline::before {
  content: "\ea32";
}

.icon-strikethrough::before {
  content: "\ea33";
}

.icon-crop::before {
  content: "\ea34";
}

.icon-duplicate::before {
  content: "\ea35";
}

.icon-bin::before {
  content: "\ea36";
}

.icon-lock::before {
  content: "\ea37";
}

.icon-unlocked::before {
  content: "\ea38";
}

.icon-arrow-up::before {
  content: "\ea39";
}

.icon-arrow-down::before {
  content: "\ea3a";
}

.icon-phone::before {
  content: "\ea3b";
}

.icon-email::before {
  content: "\ea3c";
}

.icon-notify::before {
  content: "\ea3d";
}

.icon-pen-tool::before {
  content: "\ea3e";
}

.icon-themes::before {
  content: "\ea3f";
}

.icon-tag::before {
  content: "\ea40";
}

.icon-domain::before {
  content: "\ea41";
}

.icon-menu::before {
  content: "\ea42";
}

.icon-apps::before {
  content: "\ea43";
}

.icon-zoom::before {
  content: "\ea44";
}

.icon-double-arrow-right::before {
  content: "\ea45";
}

.icon-double-arrow-left::before {
  content: "\ea46";
}

.icon-up-arrow::before {
  content: "\ea47";
}

.icon-right-arrow::before {
  content: "\ea48";
}

.icon-left-arrow::before {
  content: "\ea49";
}

.icon-down-arrow::before {
  content: "\ea4a";
}

.icon-page-setting::before {
  content: "\ea4b";
}

.icon-bill::before {
  content: "\ea4c";
}

.icon-orders::before {
  content: "\ea4d";
}

.icon-products::before {
  content: "\ea4e";
}

.icon-customers::before {
  content: "\ea4f";
}

.icon-users::before {
  content: "\ea50";
}

.icon-discounts::before {
  content: "\ea51";
}

.icon-store::before {
  content: "\ea52";
}

.icon-ring::before {
  content: "\ea53";
}

.icon-maps::before {
  content: "\ea54";
}

.icon-edit::before {
  content: "\ea55";
}

.icon-integration::before {
  content: "\ea56";
}

.icon-c-warning::before {
  content: "\ea57";
}

.icon-c-question::before {
  content: "\ea58";
}

.icon-crown::before {
  content: "\ea59";
}

.icon-calendar-date-2::before {
  content: "\ea5a";
}

.icon-print::before {
  content: "\ea5b";
}

.icon-hot-key::before {
  content: "\ea5c";
}

.icon-keyboard::before {
  content: "\ea5d";
}

.icon-blog::before {
  content: "\ea5e";
}

.icon-chat-support::before {
  content: "\ea5f";
}

.icon-b-chat::before {
  content: "\ea60";
}

.icon-logo-facebook::before {
  content: "\ea61";
}

.icon-logout::before {
  content: "\ea62";
}

.icon-key::before {
  content: "\ea63";
}

.icon-access-key::before {
  content: "\ea64";
}

.icon-project::before {
  content: "\ea65";
}

.icon-affilate::before {
  content: "\ea66";
}

.icon-referral::before {
  content: "\ea67";
}

.icon-dock-bottom::before {
  content: "\ea68";
}

.icon-dock-right::before {
  content: "\ea69";
}

.icon-dock-left::before {
  content: "\ea6a";
}

.icon-dock-top::before {
  content: "\ea6b";
}

.icon-format-left::before {
  content: "\ea6c";
}

.icon-format-right::before {
  content: "\ea6d";
}

.icon-align-left::before {
  content: "\ea6e";
}

.icon-align-right::before {
  content: "\ea6f";
}

.icon-align-top::before {
  content: "\ea70";
}

.icon-align-bottom::before {
  content: "\ea71";
}

.icon-distribute-vertical::before {
  content: "\ea72";
}

.icon-distribute-horizontal::before {
  content: "\ea73";
}

.icon-align-center-vertical::before {
  content: "\ea74";
}

.icon-align-center-horizontal::before {
  content: "\ea75";
}

.icon-caps-all::before {
  content: "\ea76";
}

.icon-check-single::before {
  content: "\ea77";
}

.icon-d-check::before {
  content: "\ea78";
}

.icon-g-check::before {
  content: "\ea79";
}

.icon-delivery::before {
  content: "\ea7a";
}

.icon-palette::before {
  content: "\ea7b";
}

.icon-border-radius::before {
  content: "\ea7c";
}

.icon-sync::before {
  content: "\ea7d";
}

.icon-copy::before {
  content: "\ea7e";
}

.icon-flip-vertical::before {
  content: "\ea7f";
}

.icon-flip-horizontal::before {
  content: "\ea80";
}

.icon-triangle-right::before {
  content: "\ea81";
}

.icon-triangle-left::before {
  content: "\ea82";
}

.icon-refresh::before {
  content: "\ea83";
}

.icon-filter::before {
  content: "\ea84";
}

.icon-mask::before {
  content: "\ea85";
}

.icon-backward::before {
  content: "\ea86";
}

.icon-forward::before {
  content: "\ea87";
}

.icon-rotate::before {
  content: "\ea88";
}

.icon-grid-view::before {
  content: "\ea89";
}

.icon-element-repeater::before {
  content: "\ea8a";
}

.icon-spin-lucky::before {
  content: "\ea8b";
}

.icon-ab-testing::before {
  content: "\ea8c";
}

.icon-report::before {
  content: "\ea8d";
}

.icon-inbox::before {
  content: "\ea8e";
}

.icon-at-sign::before {
  content: "\ea8f";
}

.icon-send-message::before {
  content: "\ea90";
}

.icon-chat-user::before {
  content: "\ea91";
}

.icon-credit-card::before {
  content: "\ea92";
}

.icon-re-open::before {
  content: "\ea93";
}

.icon-add-user::before {
  content: "\ea94";
}

.icon-image::before {
  content: "\ea95";
}

.icon-attach::before {
  content: "\ea96";
}

.icon-smile::before {
  content: "\ea97";
}

.icon-share::before {
  content: "\ea98";
}

.icon-visit-link::before {
  content: "\ea99";
}

.icon-notes::before {
  content: "\ea9a";
}

.icon-logo-fb-simple::before {
  content: "\ea9b";
}

.icon-campaign-flow::before {
  content: "\ea9c";
}

.icon-logo-messenger::before {
  content: "\ea9d";
}

.icon-cart-add::before {
  content: "\ea9e";
}

.icon-cart::before {
  content: "\ea9f";
}

.icon-shopping-cart::before {
  content: "\eaa0";
}

.icon-basket::before {
  content: "\eaa1";
}

.icon-pin::before {
  content: "\eaa2";
}

.icon-qrcode::before {
  content: "\eaa3";
}

.icon-move-item::before {
  content: "\eaa4";
}

.icon-move-2::before {
  content: "\eaa5";
}

.icon-remove-background::before {
  content: "\eaa6";
}

.icon-status::before {
  content: "\eaa7";
}

.icon-align-right-2::before {
  content: "\eaa8";
}

.icon-antenna::before {
  content: "\eaa9";
}

.icon-broadcast::before {
  content: "\eaaa";
}

.icon-discounts::before {
  content: "\ea51";
}
* {
    box-sizing: border-box;
    margin: 0 auto;
}

.ladiui.aside.mobile {
    display: none !important;
}

.ladiui.wrapper {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    min-width: 1366px;
    height: 0;
}

.ladiui.topbar {
    height: 64px;
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-white);
    position: fixed;
    width: 100%;
    z-index: 10;
    top: var(--notification-height);
}

.ladiui.main {
    position: relative;
    margin-left: 74px;
    width: calc(100% - 74px);
}

.ladiui.topbar-menu {
    position: fixed;
    padding: 19px 0px 19px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--main-secondary);
}

.ladiui.topbar-menu-right {
    position: fixed;
    right: 20px;
}

.ladiui.btn-group {
    padding: 14px 0px 14px 0px;
}

.ladiui.app-tools img {
    pointer-events: none;
}

.ladiui.tool-notificiations {
    padding: 19px 12px 19px 24px;
}

.ladiui.noti.dropdown-menu {
    /* left: 30px; */
    position: absolute;
    width: 400px;
    right: 122px;
    margin-top: 15px
}

.ladiui.noti-content {
    display: flex;
    padding: 16px;

}

.ladiui.noti-content.active {
    background-color: rgba(24, 82, 250, .05) !important;
}

.ladiui.noti-content:hover {
    background-color: var(--main-line);
}

.ladiui.noti-header {
    padding: 6px 16px 18px;
    display: flex;
    justify-content: space-between;
}

.ladiui.noti-header-right {
    color: var(--main-primary);
}

.ladiui.noti-header-right i {
    margin-right: 4px;
}

.ladiui.noti-i {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    pointer-events: none;
}

.ladiui.noti-text {
    margin-left: 56px;
    cursor: pointer;
    pointer-events: none;
}

.ladiui.noti-sub {
    margin-top: 4px;
    color: var(--main-secondary);
}

.ladiui.noti-time {
    margin-top: 8px;
    color: var(--main-secondary);
}

.ladiui.tool-help {
    padding: 20px 12px 20px 12px;
}

.ladiui.tool-help .dropdown-menu {
    position: absolute;
    margin-top: 15px !important;
    right: 0;
    width: 200px;
}

.ladiui.tool-help .dropdown-item {
    display: flex;
    align-items: center;
}

.ladiui.tool-language {
    padding: 20px 12px 20px 12px;
}

.ladiui.language-img {
    cursor: pointer;
    pointer-events: none;
    color: white;
    font-size: 0;
}

.ladiui.tool-language img {
    border-radius: 20px;
}

.ladiui.tool-language .dropdown-menu {
    min-width: 126px;
    left: 70px;
    position: absolute;
    margin-top: 15px;
    /* right: 0; */
}

.ladiui.my-account {
    cursor: pointer;
}

.ladiui.my-account.dropdown-toggle::after {
    content: none;
}

.ladiui.dropdown-toggle.nav-bar {
    min-width: 200px;
}

.ladiui.my-account-avata {
    padding: 12px 0px 12px 12px;
    pointer-events: none;
}

.ladiui.my-account-info {
    padding-left: 16px;
    pointer-events: none;
}

.ladiui.my-account-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    pointer-events: none;
}

.ladiui.my-account-type {
    padding: 2px 0px 0px 0.5px;
}

.ladiui.account.dropdown-menu {
    width: 332px;
    /* left: 220px; */
    position: absolute;
    right: 0;
}

.ladiui.account .dropdown-item {
    padding: 12px 21px;
}

.ladiui.account {
    position: relative;

}


.ladiui.account-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.account-header-text {
    pointer-events: none;
}

.ladiui.account-content {
    padding: 14px 24px 0;
}

.ladiui.account-content-footer {
    padding: 0px 24px;
    margin-bottom: 12px;
}

.ladiui.account-footer {
    border-top: 1px solid var(--main-line);
}

.ladiui.account-avatar {
    margin-right: 16px;
    display: flex;
    position: relative;
}

.ladiui.account-avatar a {
    padding: .5px;
    border-radius: 20px;
    background-color: var(--main-white);
    position: absolute;
    top: 42px;
    left: 42px;
}

.ladiui.account-body-content {
    pointer-events: none;
}

.ladiui.account-avatar img {
    pointer-events: none;
}

.ladiui.avt {
    width: 64px;
    height: 64px;
}

.ladiui.avartar {
    pointer-events: none;
}

.ladiui.account-body-item {
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.ladiui.my-account-type i {
    margin-right: 0;
    /* position: relative; */
}

.ladiui.dropdown-item.account-body-item i {
    margin-right: 0;
}

.ladiui.account-tick {
    background-color: inherit;
    position: absolute;
    right: 24px;
}

.ladiui.account-body-item:first-child {
    margin-top: 12px;
}

.ladiui.account-body-item:last-child {
    margin-bottom: 12px;
}

.ladiui.account-body-item:hover {
    background-color: var(--main-line);
}

.ladiui.body-avatar {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
    pointer-events: none;
}

.ladiui.body-avatar img {
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;

}

.ladiui.account-status {
    display: flex;
    justify-items: center;
    margin-top: 4px;
}

.ladiui.account-body-item.active .body-avatar {
    border: 2px solid var(--main-primary);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-tick {
    background-color: var(--main-primary);
}

.ladiui.account-body-item.active .body-avatar img {
    border: 2px solid var(--main-white);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-name {
    color: var(--main-primary);
}

.ladiui.account-duration {
    margin-left: 8px;
}

.ladiui.icon-drd {
    padding: 0px 20px 0px 8px;
}

.ladiui.content-navigation .dropdown {
    display: flex;
    flex-direction: column-reverse;
}

.ladiui.new-page.dropdown-menu {
    width: 400px;
    padding: 0;
    margin-bottom: 44px;
}

.ladiui.new-page.dropdown-item {
    display: flex;
    align-items: center;
    padding: 16px;
}

.ladiui.content-sub {
    min-width: 530px;
}

.ladiui.new-page-content {
    margin-left: 8px;
}

/*Hover*/
.ladiui.nav {
    position: fixed;
    z-index: 1000;
    top: var(--notification-height);
    left: 0;
    width: 74px;
    height: 100vh;
    background-color: var(--main-line);
    transition: width .3s;
}

.ladiui.logo-item.logo {
    padding: 16px 21px 16px 21px;
    position: relative;
    opacity: 1;
}

.ladiui.logo-item.logo-hover {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}


.ladiui.nav:hover .logo-item.logo-hover {
    position: relative;
    opacity: 1;
    transition: opacity .5s;

}

.ladiui.nav:hover .logo-item.logo {
    position: absolute;
}

.ladiui.nav:hover {
    display: block;
    width: 215px;
}

.ladiui.menu-text {
    position: absolute;
    pointer-events: none;
    left: 50px;
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: opacity .2s, width .3s;
}

@-webkit-keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ladiui.nav:hover .menu-text {
    opacity: 1;
    width: 100%;
}

.ladiui.nav:hover .menu-item {
    width: 215px;
}

.ladiui.menu-item {
    padding: 16px 0px 16px 23px;
    width: 74px;
    transition: width .3s;
    border-left: 2px solid var(--secondary-color);
}

.ladiui.menu-item a i {
    background-color: var(--main-secondary);
}

.ladiui.menu-item .icon-prev {
    margin-left: 16px;
}

.ladiui.menu-item:hover .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item:hover i {
    background-color: var(--main-primary);
}


.ladiui.logo-item.logo-hover {
    padding: 16px 59px 16px 21px;
}

.ladiui.menu-item.active {
    background-color: var(--main-white);
    border-left: 2px solid var(--main-primary);
}

.ladiui.menu-item.active i {
    background-color: var(--main-primary);
}

.ladiui.menu-item .icon-prev {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ladiui.menu-item.active .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item .dropdown-toggle {
    min-width: 50px;
    width: 50px;
}

.ladiui.menu-item .dropdown-toggle:focus {
    border: none;
}

.ladiui.menu-item .dropdown-toggle::after {
    content: none;
}

.ladiui.menu-item .dropdown-menu {
    padding: 0;
    box-shadow: none;
    background-color: inherit;
}

.ladiui.nav:hover .dropdown-toggle {
    min-width: 200px;
    width: 200px;
    transition: width .3s;
}

.ladiui.nav:hover .ladiui.menu-child {
    position: relative;
    opacity: 1;
    width: 100%;
}

.ladiui.menu-child {
    position: absolute;
    opacity: 0;
    width: 0;
    transition: opacity .2s;
}

.ladiui.menu-child-item {
    padding: 12px 0 12px 34px;
}

.ladiui.menu-child-item:first-child {
    padding: 24px 0 12px 34px;
}

.ladiui.menu-child li a:hover {
    color: var(--main-primary);
}

.ladiui.menu-child li a.active {
    color: var(--main-primary);
}


.ladiui.menu-child li a {
    color: var(--main-secondary);
}

.ladiui.menu-text {
    white-space: nowrap;
    padding-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}


.ladiui.head-noti {
    margin-bottom: 4px;
}

.ladiui.tag-form {
    display: inline-table;
}

.ladiui.dropdown-menu.custom-header {
    min-width: 200px;
}

.facet-container {
    width: 330px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.facet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    background: #eee;
    padding: 5px;
    width: 143px;
    min-height: 1.5em;
    font-size: 0.85em;
}

.facet-list li {
    margin: 5px;
    padding: 5px;
    font-size: 1.2em;
    width: 120px;
}

.facet-list li.placeholder {
    height: 1.2em
}

.facet {
    border: 1px solid #bbb;
    background-color: #fafafa;
    cursor: move;
}

.facet.ui-sortable-helper {
    opacity: 0.5;
}

.placeholder {
    border: 1px solid orange;
    background-color: #fffffd;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--main-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}
.ladiui.block-banner-affiliate.custom-v2 {
    width: 100%;
}

.ladiui.block-banner-affiliate.custom-v2 a img {
    width: 100%;
    object-fit: cover;
}

.ladi-popupx-inline[data-ladipage-id="66418f519ae79400116994ca"] .ladi-popupx {
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
}

#ladi-popupx-backdrop {
    pointer-events: none !important;
}

.ladiui.affiliate-account-box .membership-class-group {
    display: flex;
    align-items: center;
    grid-gap: 14px;
    gap: 14px;
}

.ladiui.affiliate-account-box .membership-class-group .item {
    padding: 2px 12px;
    border-radius: 2px;
}

.ladiui.affiliate-account-box .membership-class-group .item span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
}

.ladiui.affiliate-account-box .membership-class-group .item.Silver {
    background-color: #A2A2A2;
}
.ladiui.affiliate-account-box .membership-class-group .item.Gold {
    background-color: #F2A91B;
}
.ladiui.affiliate-account-box .membership-class-group .item.Diamond {
    background-color: #1B97F2;
}

.ladiui.affiliate-account-box .item-link-help {
    position: relative;
}

.ladiui.affiliate-account-box .item-link-help span {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: #1C00C2;
}

.tooltip .tooltiptext.max-w-275:before {
    max-width: 275px !important;
}

.affiliate-utm-source {
    overflow: hidden;
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}
/* ----------------------------------------modal-------------------------------------------------------- */
.ladiui.modal .search-field {
    height: 32px;
}

.ladiui.modal .search-group i {
    top: 3px;
}

/*-----------------------------------------Ladipage Home---------------------------------------*/
.ladiui.content {
    height: 90vh;
    position: relative;
    top: 64px;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.content-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.content-sub {
    max-width: 530px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.sub-weight {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.content-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.ladiui.content-navigation .dropdown-toggle::after {
    content: none;
}

.ladiui.btn-primary {
    margin: 0px 0px 0px 0px;
}

.ladiui.end {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 0px 24px;
}

.ladiui.tutorial a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.tutorial {
    border-bottom: 1px solid var(--main-primary);
}

/*---------------------------------------------------------------------------------------Landingpage list-------------------------------------------------------------------------------*/
.ladiui.content-main {
    position: absolute;
    top: 64px;
    border-left: 1px solid var(--main-line);
    width: calc(100% - 236px);
    min-width: 1012px;
    height: 91.5vh;
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 40px 24px;
}

.ladiui.content-main1 {
    position: absolute;
    top: 64px;
    width: 100%;
    min-width: 1012px;
    /* height: 82vh; */
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 20px 40px 24px;
}

.ladiui.content-main-full {
    padding: 88px 20px 40px 24px;
}

.ladiui.main-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.ladiui.content-list-landingpage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
}

.ladiui.content-main-title {
    justify-content: space-between;
}

.ladiui.content-tag {
    max-width: 236px;
    width: calc(100% - 236px);
    height: 100%;
    padding: 24px 24px 0px 23px;
    position: fixed;
    top: 64px;
    left: 74px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
}

.ladiui.content-tag-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.content-tag-setting {
    position: absolute;
    right: 24px;
}


.ladiui.btn-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.btn-custom-img {
    padding: 9px 8px 9px 0px;
    pointer-events: none;
}

.ladiui.btn-custom-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
    pointer-events: none;
}

.ladiui.table {
    margin-top: 25px;
    min-width: 1012px;
    width: 100%;
}

.ladiui.btn-group.custom-btn-list-landingpage {
    margin-left: 24px;
}

.ladiui.content-main-tool {
    margin-top: 28px;
}

.ladiui.custom-btn-list-landingpage {
    min-width: 154px;
}

.ladiui-btn-tag.landingpage-list {
    margin: 4px 4px 4px 0px;
}

.ladiui-btn-tag.landingpage-list:hover {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui-btn-tag.landingpage-list:active {
    background-color: var(--main-primary);
    color: var(--main-white);
}

.ladiui.content-main-tool {
    height: 36px;
}


/*--------------------------------------------------------------------------------Ladipage banner-ads-list------------------------------------------------------------------------------*/
.ladiui.dropdown-toggle.custom-pre-page {
    min-width: 64px;
}

.ladiui.dropdown-text.custom-pre-page {
    margin: 0;
    padding: 0px 16px 0px 0px;
}

.ladiui.dropdown-toggle.custom-pre-page:after {
    margin: 12px 12px 12px 0px;
}

.ladiui.dropdown-menu.custom-pre-page {
    min-width: 64px;
    bottom: 0;
}

.ladiui.btn-group.custom-pre-page {
    padding: 0;
}

.ladiui.from-filter {
    min-height: 32px;
}

.ladiui.showing {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
    ;
}

.ladiui.showing span {
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    color: var(--main-default);
}

.ladiui.search-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.ladiui.col-one.custom-th-banner {
    min-width: 344px;
}

.ladiui.setting-content {
    width: 236px;
    padding: 24px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.ladiui.content-tag-text {
    color: var(--main-default);
}

.ladiui.ladiui.content-tag-text.active {
    color: var(--main-primary);
}

.ladiui.setting-name {
    margin-bottom: 18px;
}

.ladiui.setting-item {
    margin: 32px 0;
}

.ladiui.switch-content.form-vertical {
    margin-bottom: 10px;
}

.ladiui.form-vertical {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.ladiui.form-vertical:last-child {
    margin-bottom: 0;
}

.ladiui.input-name {
    width: 100px;
    margin-right: 24px;
}

.ladiui.setting-input-name {
    width: 150px;
    margin-right: 24px;
}

.ladiui.col-tow.custom-th-banner {
    min-width: 344px;
}

.ladiui.col-three.custom-th-banner {
    min-width: 269px;
}

/* -------------------------------------------------Setting---------------------------------------- */
.ladiui.setting.content-main table {
    margin-top: 18px;
}

.ladiui.setting-content a:hover {
    color: var(pr);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.content-parent {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content-left {
    position: fixed;
    top: calc(var(--notification-height) + 65px);
}

.ladiui.shared.setting-content-right {
    width: 636px;
    padding-bottom: 18px;
}

.ladiui.setting-content-right:last-child {
    border-bottom: none;
}

.ladiui.setting-content-right .btn-group {
    margin: 0 20px 0 0;
}

.ladiui.setting-content-right .btn-group:first-child {
    margin-left: 0px;
}

.ladiui.content-parent .table {
    margin-top: 0;
}

.ladiui.content-parent:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.ladiui.switch-btn.form-vertical {
    justify-content: end;
    margin-top: 34px;
}

.ladiui.switch-btn.form-vertical button {
    margin-right: 24px;
}

.ladiui.switch-btn.form-vertical button:last-child {
    margin-right: 0;
}

.ladiui.col-six.custom-th-banner {
    min-width: 18px;
}

.ladiui.icon.icon-ldp-list {
    margin-right: 16px;
}



/*--------------------------------------------------------------------------------banner grid----------------------------------------------------------------------------------------*/

.ladiui.card-custom {
    width: 310px;
    height: 250px;
    -webkit-filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
            filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    border-radius: 2px;
}

.ladiui.card-img-top-custom {
    width: 310px;
    height: 250px;
    position: absolute;
}


.ladiui.card-body-custom {
    background-color: var(--main-white);
    position: relative;
    height: 60px;
    top: 190px;
}

.ladiui.card-title-custom {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin: 8px 0px 4px 17.47px;
}

.ladiui.text-time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
    margin-left: 8.73px;
}

.ladiui.card-custom-date {
    margin: 0px 0px 0px 17.47px;
}

.ladiui.banner-ads-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    grid-row-gap: 24px;
    row-gap: 24px;
    margin-top: 24px;
}

.ladiui.content-main-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

.ladiui.content-main-tool-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.ladiui.table-show {
    display: none;
}

.ladiui.grid-show {
    display: none;
}

.ladiui.icon-list-view.tab-link,
.ladiui.icon-grid-view.tab-link {
    border-bottom: none;
}

/* ---------------------------------------------------------------------------------------intro---------------------------------------------------------------------------------- */
.ladiui.intro-content {
    padding-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.intro-head {
    margin-bottom: 19px;
}

.ladiui.intro-header {
    text-align: center;
    margin-bottom: 65px;
}

.ladiui.intro-body {
    display: flex;
    flex-direction: row;
    margin-bottom: 48px;
}

.ladiui.intro-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 243px;
    text-align: center;
    margin: 0 121px;
}

.ladiui.intro-body-content i {
    margin-bottom: 53px;
}

.ladiui.intro-body-content span {
    margin-bottom: 8px;
}

.ladiui.intro-footer {
    width: 528px;
    margin-bottom: 24px;
    text-align: center;
}

/*----------------------------------------------------------------------------------------custom table dynamic--------------------------------------------------------------------------------------------------------------*/
.ladiui.col-one.custom-dynamic {
    min-width: 324px;
}

.ladiui.col-tow.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-three.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-threes.custom-dynamic {
    min-width: 193px;
}

.ladiui.col-for.custom-dynamic {
    min-width: 132px;
}

.ladiui.col-fors.custom-dynamic {
    min-width: 112px;
}

.ladiui.col-five.custom-dynamic {
    min-width: 112px;
}

/*Custom form dynamic*/
.ladiui.modal-content.custom-dynamic {
    min-width: 824px;
    padding: 24px;
}

.ladiui.modal-header.dynamic {
    padding: 0px 0px 4px 0px;
}

.ladiui.item.dynamic {
    margin-top: 32px;
}

.ladiui.btn-group-dynamic {
    padding: 0px;
}

.ladiui.btn-group.dynamic {
    padding: 0px;
}

.ladiui.item-form.dynamic {
    align-items: center;
}

.ladiui.switch.sw-lg.dynamic {
    margin: 0px;
}

.ladiui.item-form {
    margin: 0px;
}

.ladiui.item-form {
    padding: 0px;
}



.ladiui.search-group .dropdown-menu {
    min-width: 270px;
}


/*----------------------------------------------------------------------------------------Custom modal home----------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-ldp-width {
    width: 600px;
}

.ladiui.custom-ldp {
    padding: 24px 24px 24px 24px;
}

.ladiui.modal-header.custom-ldp {
    padding: 0px 0px 12px 0px;
}

.ladiui.form-group-custom {
    margin-top: 28px;
}

.ladiui.modal-footer.custom-ldp {
    padding: 48px 0px 0px 0px;
}

.ladiui.form-check-inputs-custom {
    margin-top: 32px;

}

.ladiui.radio.custom-ldp-radio {
    margin-top: 8px;
}

.ladiui.dropdown-toggle.custom-main-tool {
    margin-left: 24px;
}

.ladiui.search-width {
    min-width: 270px;
}

/*----------------------------------------------------------------------------------------custom modal popup--------------------------------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-popup-width {
    width: 718px;
}

.ladiui.label-embel-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-text-color);
    margin-top: 20px;
}

.ladiui.input-embel-code {
    margin-top: 16px;
}

/*----------------------------------------------------------------------------------------custom dropdown people--------------------------------------------------------------------------------------------------------------*/

.ladiui.dropdown-toggle.custom-people {
    min-width: 168px;
}

.ladiui.dropdown-menu.custom-people {
    position: absolute;
    left: 24px;
}

.ladiui.dropdown-menu.custom-people {
    padding: 0px;
}

/* --------------------------------------------------------------------------------------------Domain---------------------------------------------------------------------------------------------- */
.ladiui.domain .modal-dialog {
    width: 600px;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

.ladiui.modal .btn-group {
    padding: 0;
    width: 552px;
}

.ladiui.modal .btn-group .dropdown-toggle {
    width: 552px;
}

.ladiui.modal .btn-group .dropdown-menu {
    width: 552px;
}

.ladiui.domain .span-text {
    margin-right: 40px;
}

.ladiui.domain .table-vertical td {
    padding: 0;
}


.ladiui.modal .domain-action.btn-group {
    width: 120px;
    margin-right: 16px;
}

.ladiui.modal .domain-action.btn-group .dropdown-toggle {
    min-width: 120px;
    width: 120px;
}

.ladiui.modal .domain-action.btn-group .dropdown-menu {
    min-width: 120px;
    width: 120px;
}

/* -------------------------------------------------------------------------------------------------------------Member------------------------------------------------------------------------------------------------------ */
.ladiui.member .search-field {
    width: 295px;
}

.ladiui.member .custom-btn-list-landingpage {
    width: 188px;
}

.ladiui.member .table {
    margin-top: 44px;
}

.ladiui.member .ladiui.content-main-tool {
    margin-top: 18px;
}

.ladiui.member .col-one {
    min-width: 150px;
}

.ladiui.member .col-three {
    min-width: 150px;
}

.ladiui.member table .btn-group {
    padding: 11.5px 0;
}

.ladiui .modal .btn-group.decent {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-toggle {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-menu {
    min-width: 222px;
    width: 222px;
}

.ladiui.auto-height.form-control {
    word-break: normal;
    word-wrap: break-word;
}

.ladiui.share-recource {
    display: grid;
    grid-template-columns: auto auto auto;
}

.ladiui.share-recource .span-text {
    margin: 0;
    padding: 8px 0 16px;
}

.ladiui.list-recource {
    display: none;
}

.ladiui.form-group .select-search .btn {
    max-width: none;
    text-overflow: unset;
}

.ladiui.form-group .select-search .dropdown-text {
    overflow: unset;
}

.ladiui.status.form-group .span-text {
    margin: 0 62px;
}

.ladiui.status.form-group .span-text:first-child {
    margin-left: 0;
}

.ladiui.status.form-group .span-text:last-child {
    margin-right: 0;
}

.ladiui.member .list-team .col-one {
    min-width: 220px;
}

.ladiui.member .list-team .col-five {
    min-width: 28px;
}

/*-----------------------------------------------------------------------------------------------------custom modal dropdown----------------------------------------------------------------------------------------*/
.ladiui.custom-ldp-width1 {
    min-width: 450px;
}

.ladiui.text.on-access-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.ladiui.modal-footer.custom-ldp1 {
    padding: 24px 0px 0px 0px;
    justify-content: left;
}

.ladiui.form-check-inputs-custom1 {
    margin-top: 20px;
}

.ladiui.radio.custom-ldp-radio1 {
    margin-top: 16px;
    margin-bottom: 8px;
}

.ladiui.member .member-img-left {
    margin-right: 16px;
}

.ladiui.member .member-img-left img {
    height: 32px;
    width: 32px;
}

.ladiui.member .member-control {
    margin: 8px 0;

}

.ladiui.member .member-control:first-child {
    margin-top: 0;
}

.ladiui.member .member-control:last-child {
    margin-bottom: 0;
}

.ladiui.modal-header.custom-data-backup {
    padding: 0px 0px 4px 0px;
}

.ladiui.modal-footer.custom-ldp-decentralization {
    padding: 54px 0px 0px 0px;
}

.ladiui.help-footer {
    margin-right: 8px;
}

.ladiui.modal-footer-link a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.list-user {
    margin-top: 32px;
}

.ladiui.container-list-user {
    margin: 8px 0px 16px 0px;
}

.ladiui.text-sub-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-title-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.container-list-user-left {
    display: flex;
    flex-direction: column;
}

.ladiui.container-list-user,
.ladiui.container-list-user-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/*----------------------------------------------------------------------------------------custom button popup----------------------------------------------------------------------------------------*/
.ladiui.btn-outline-primary.custom-popup {
    font-weight: 500;
    margin-right: 24px;
}

.ladiui.modal-header.custom-popupX {
    padding: 0px 0px 4px 0px;
}

.ladiui.text-sub-notification {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-sub-bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.modal-footer.custom-ldp-popupX {
    padding: 48px 0px 0px 0px;
}

/* --------------------------------------------------------------------------------------Access------------------------------------------------------------------------------ */
.ladiui.access.content-main-tool .search-group {
    width: 270px;
}

.ladiui.modal.key-access .modal-dialog {
    width: 963px;
}

.ladiui.modal.key-access .search-group {
    width: 270px;
}

.ladiui.modal.key-access .col-first {
    width: 40px;
}

.ladiui.modal.key-access .col-one {
    min-width: 80px;
    width: 80px;
}

.ladiui.modal.key-access .col-tow {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-three {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-four {
    min-width: 116px;
    width: 116px;
    text-align: center;
}

.ladiui.modal.key-access .col-five {
    min-width: 136px;
    width: 130px;
    text-align: center;
}

.ladiui.modal.key-access .col-six {
    min-width: 16px;
    width: 85px;
    text-align: center;
}

.ladiui.modal.key-access .col-seven {
    min-width: 107px;
    width: 107px;
}

.ladiui.modal.key-access .btn-group {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access .btn-group .dropdown-toggle {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access table .btn-group .dropdown-menu {
    min-width: 175px;
    width: 175px;
}

.ladiui.modal.key-access .btn-group .dropdown-menu {
    min-width: 65px;
    width: 65px;
}

.ladiui.create-key .dropdown-toggle {
    min-width: 100px;
}

.ladiui.create-key .dropdown-toggle::after {
    content: none;
}

.ladiui.create-key .dropdown-menu {
    min-width: 145px;
    width: 145px;
}

.ladiui.confirm-2 .modal-footer {
    margin-top: 20px;
}

.ladiui.funnel-text {
    margin-left: 50px;
}

.ladiui.funnel-text label {
    margin-left: 246px;
}

.ladiui.funnel-text label:first-child {
    margin-left: 0;
}

.ladiui.funnel-wrapper {
    display: flex;
}

.ladiui.group-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-color);
    width: 712px;
    border-radius: 2px;
}

.ladiui.group-input .form-control {
    border: 1px solid var(--main-white);
    padding-left: 8px;
}

.ladiui.space-between {
    width: 2px;
    height: 20px;
    background-color: var(--secondary-color);
}

.ladiui.wrapper-group .ladiui-btn-tag {
    width: 34px;
    height: 34px;
    padding: 6px;
    text-align: center;
    margin-right: 8px;
}

.ladiui.sortable-line {
    margin: 8px 0;
}

body {
    counter-reset: section;
}

.ladiui.sortable-line .ladiui-btn-tag::before {
    counter-increment: section;
    content: counter(section);
}

.ladiui.sortable-line:first-child {
    margin-top: 0;
}

.ladiui.sortable-line:last-child {
    margin-bottom: 0;
}

.ladiui.first.form-control {
    width: 552px;
}

.ladiui.group-input .icon-ldp-drag {
    cursor: grab;
    pointer-events: all;
    margin-right: 10px;
}

.ladiui.sortable-line .icon-ldp-delete {
    cursor: pointer;
    pointer-events: all;
    margin-left: 10px;
}

.ladiui.funnel .proviso {
    padding: 16px 0 0 0;
}

.ladiui.funnel td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ladiui.funnel .col-first {
    width: 16px;
}

.ladiui.funnel .col-one {
    width: 470px;
}

/*----------------------------------------------------------------------------------------dynamic-report----------------------------------------------------------------------------------------*/
.ladiui.dynamic-report-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.dynamic-report-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.dynamic-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary)
}

.ladiui.dynamic-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-left: 8px;
}

.ladiui.dropdown-select-dynamic {
    margin-top: 10px;
}

.ladiui.btn-group.pull-right {
    margin-left: 24px;
}

.ladiui.dynamic-report-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 50px;
}

.ladiui.report-total-item {
    border: 1px solid var(--main-primary);
    border-radius: 10px;
    min-width: 188px;
    height: 106px;
}

.ladiui.report-total-item-lable {
    padding: 24px 0px 0px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.report-total-item-number {
    padding: 4px 0px 0px 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report-bg {
    border: 1px solid var(--main-line);
    margin-top: 50px;
    border-radius: 5px;
}

.ladiui.table.boder {
    margin-top: 0px;
}

.ladiui.table-vertical.header.item th {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.item td {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.end td {
    padding: 16px 16px 25px 16px;
}

.ladiui.search-action.custom {
    margin: 0px 16px 16px 16px;
}

.ladiui.report-convert {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 24px;
    margin-top: 24px;
}

.ladiui.report-convert-left {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-right {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin: 16px 0px 16px 0px;
}

.ladiui.report-convert-right {
    text-align: center;
}

.ladiui.report-convert-right img {
    margin-top: 32px;
}

/* ----------------------------------------------------------------------------------conversion-tool--------------------------------------------------------------------------------------- */
.ladiui.conversion-tab {
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.conversion-content-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin-bottom: 16px;
}

/*custom modal dynamic*/
.ladiui.modal .btn-group.custom-form-dynamic {
    width: 180px;
}

.ladiui.modal .btn-group .dropdown-toggle.custom-form-dynamic,
.ladiui.modal .btn-group .dropdown-menu.custom-form-dynamic {
    width: 180px;
}

.ladiui.item-form.sub1 {
    margin-left: 24px;
}

.ladiui.switch.custom-form-dynamic {
    margin: 0px;
}

.ladiui.item-form.custom-form-dynamic {
    margin-left: 42px;
}

.ladiui.title-text-form-lever2.custom-form-dynamic {
    margin-bottom: 16px;
}

.ladiui.item.flex-row.custom-form-dynamic {
    align-items: flex-start;
}

.ladiui.dropdown-menu.custom-dropdown-dynamic {
    width: 200px !important;
}

.ladiui.btn-group.col-2,
.ladiui.dropdown-toggle.col-2,
.ladiui.dropdown-menu.col-2 {
    width: 320px !important;
    margin-right: 16px;
}

.ladiui.itemss {
    justify-content: flex-start;
}

.ladiui.items-1 {
    width: 310px;
}

.ladiui.items-2 {
    position: relative;
    width: 310px;
    margin-left: 16px;
}

.ladiui.items-3 {
    width: 90px;
    margin-left: 16px;
}

.ladiui.items-4 {
    position: relative;
    width: 704px;
}

.ladiui.flex-row.custom-create-dynamic {
    margin-top: 4px;
}

.ladiui.modal-footer.custom-ldp-popupX.mod {
    padding-top: 36px;
}

.ladiui.infomation-link {
    margin-bottom: 19px;
    margin-left: 4px;
}

.ladiui.infomation-links {
    margin: 0px 0px 12px 4px;
}

.ladiui.dropdown-toggle.custom-main-tool.dynamic,
.ladiui.dropdown-menu.custom-dynamic {
    width: 320px !important;
    margin-left: 16px;
}

.ladiui.btn-group.custom-dynamic {
    width: 336px !important;
}

.ladiui.bg-form-custom.custom-dynamic {
    margin-top: 28px;
}

.ladiui.subtitle-2.custom-dynamic {
    margin-top: 8px;
    font-weight: 400;
    margin-left: 326px;
}

.ladiui.icon-add-input {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ladiui.icon-add-input1 {
    position: absolute;
    top: 5px;
    right: 8px;
}

.ladiui.flex-row.custom-dynamic-s {
    text-align: center;
}

.ladiui.infomation-linkss {
    margin-left: 8px;
}

.ladiui.icon-search.custom {
    display: block;
    position: relative;
    left: 0px;
    margin: 18px 8px 2px 0px;
}

.ladiui.search-tag-fixed {
    align-items: baseline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-mute)
}

.ladiui.inpud-search-tag-fixed {
    position: relative;
    bottom: 3px;
    width: 165px;
}

.ladiui.delete-right {
    margin-left: 16px;
}

.ladiui.dropdown-group-form {
    max-width: 744px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ladiui.dropdown-toggle.select-dropdown.col-3 {
    width: 280px !important;
}

.ladiui.btn-group.ctm {
    width: 196px !important;
}

.ladiui.btn-group.ctms {
    width: 280px !important;
    margin-left: 16px;
}

.ladiui.dropdown-menu.ctms {
    width: 280px !important;

}

.ladiui.btn-group.ctm.col2 {
    margin-left: 16px;
}

.ladiui.conversion.content-main {
    display: none;
}

.ladiui.conversion.main .tab-link {
    border-bottom: none;
    margin-left: 12px;
}

/* ---------------------------------------------------------------------------------------Tracking global --------------------------------------------------------------------------------------- */
.ladiui.nav-tracking {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link.active {
    border-bottom: 2px solid var(--main-primary);
}

.ladiui.tracking-area .ladiui.tab-content .tab-pane {
    margin-top: 0;
}

.ladiui.tracking-list .col-four {
    min-width: 200px;
    width: 200px;
}

.ladiui.tracking-list .col-first {
    min-width: 40px;
    width: 40px;
}

.ladiui.account-tracking img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

/* ------------------------------------------------------------------------------------------conversion-link-------------------------------------------------------------------------------------------- */
.ladiui.grid-link {
    width: 795px;
    height: 384px;
    display: grid;
    grid-template-columns: 263px 263px 263px;
    grid-template-rows: 136px 136px 136px 136px 136px 136px 136px 136px 136px 136px;
    overflow-y: auto;
}

.ladiui.link-box {
    cursor: pointer;
    width: 250px;
    height: 120px;
    border: 1px solid var(--main-line);
    border-radius: 2px;
}

.ladiui.link-box:hover {
    border: 1px solid var(--main-primary);
}

.ladiui.link-header {
    pointer-events: none;
    margin-bottom: 24px;
    padding: 16px 16px 0 16px;
}

.ladiui.link-header img {
    margin-right: 16px;
}

.ladiui.link-body {
    pointer-events: none;
    padding: 0px 16px 16px 16px;
    color: var(--main-secondary);
}

.ladiui.modal-header img {
    margin-right: 16px;
}

.ladiui.header-gmail {
    width: 36px;
    height: 24px;
}

.ladiui.header-ladisale {
    width: 32px;
    height: 32px;
}

.ladiui.flex.input .ladiui.form-group {
    width: 100%;
    margin: 24px 12px 0;
}

.ladiui.flex.input .ladiui.form-group:first-child {
    margin-left: 0;
}

.ladiui.flex.input .ladiui.form-group:last-child {
    margin-right: 0;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-toggle {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-menu {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .smtp.flex .btn-group {
    min-width: 100px;
    width: 100px;
}

.ladiui.smtp-host {
    width: 264px;
}

.ladiui.smtp-port {
    width: 140px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-toggle {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-menu {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .esms.flex .btn-group {
    min-width: 264px;
    width: 264px;
}

.ladiui.form-group .btn.convert {
    margin-top: 27px;
}

.ladiui.ggform-url .form-group .form-control {
    width: 450px;
}

.ladiui.form-field {
    margin-top: 16px;
    width: 100%;
}

.ladiui.form-field .icon {
    margin: 0 16px;
}

.ladiui.form-field .icon:last-child {
    margin-right: 0;
}

.ladiui.form-field .form-control {
    width: 252px;
}

.ladiui.form-field .btn-group {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-toggle {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-menu {
    min-width: 220px;
    width: 220px;
}

.ladiui.form-field .size-checkbox {
    margin: 0 12px 0 16px;
}

.ladiui.type-account {
    align-items: center;
    text-align: center;
}

.ladiui.type-account img {
    margin: 0 8px 0 16px;
    height: 24px;
    width: 24px;
}

.ladiui.type-account .custom-img {
    height: 16px;
    width: auto;
}

/* ----------------------------------------------------------------------------------Conversion-configuration--------------------------------------------------------------------------------------------- */
.ladiui.config-box .about {
    text-align: center;
    width: 190px;
    margin-top: 8px;
}

.ladiui.config .search-group {
    min-width: 270px;
}

.ladiui.config .step {
    margin: 16px 0 32px;
}

.ladiui.config .step:last-child {
    margin: 40px 0 0;
}

.ladiui.grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 350px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group {
    padding-right: 20px;
    margin-right: -20px;
    max-height: 250px;
    overflow-y: auto;
}

.ladiui.setting-config .grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 300px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group::-webkit-scrollbar {
    width: 3px;
}

.ladiui.linking-form-group .grid-linking-form {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.grid-linking-form .type-account {
    text-align: start;
}

.ladiui.grid-linking-form .type-account .account-tracking img {
    margin-left: 0;
    width: 16px;
    height: 16px;
}

.ladiui.grid-linking-form .select-button {
    text-align: end;
}

.ladiui.grid-linking-form .select-button .edit-icon {
    margin-right: 10px;
}

/*------------------------------------------------------------------------------------------template----------------------------------------------------------------------------------*/
.ladiui.content-main-tool.custom-template {
    margin-top: 0px;
}

.ladiui.content-main-tab {
    padding-bottom: 16px;
    margin-top: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.content-main-tab-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ladiui.content-main-tab-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.ladiui.content-main-item-hover {
    display: none;
    position: absolute;
    top: 20px;
    left: -72px;
    background-color: var(--main-white);
    z-index: 999;

}

.ladiui.content-main-grid {
    min-width: 824px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ladiui.content-main-item.col4 {
    margin: 24px 24px 24px 24px;
}

.ladiui.content-main-item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 8px;
}

.ladiui.content-main-item-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    margin-bottom: 8px;
    width: 250px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-main-item-hover {
    display: block;
}

.ladiui.content-main-tab-item.tow:hover .ladiui.content-main-item-hover.tow {
    display: block;
}

.ladiui.content-main-tab-item.three:hover .ladiui.content-main-item-hover.three {
    display: block;
}

.ladiui.content-main-tab-item.for:hover .ladiui.content-main-item-hover.for {
    display: block;
}

.ladiui.content-main-item-hover.tow {
    width: 265px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-tab-text {
    color: var(--main-primary);
}

.ladiui.custom-width-tab {
    margin-left: 32px;
}

.ladiui.custom-width-tab-item {
    margin-left: 4px;
}

.ladiui.content-tab-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    pointer-events: none;
    color: var(--main-default);
}

.ladiui.content-main-tab-item.tab-link {
    padding-bottom: 0px;
}

.ladiui.content-main-tab-item.tab-link.active {
    border: 0px;
}

.ladiui.content-tab-icon {
    pointer-events: none;
}

.ladiui.tab-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    margin: 24px 0px 4px 0px;
}

.ladiui.tab-item-text-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.custom-btn {
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 2px;
    color: var(--main-black);
    background-color: var(--main-white);
    border: 1px solid var(--main-line);
    cursor: pointer;
}

.ladiui.custom-btn:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.custom-btn:active {
    color: var(--main-white);
    background-color: var(--main-primary);
}

.ladiui.custom-btn.active {
    color: var(--main-white);
    background-color: var(--main-primary);
    cursor: pointer;
}

.ladiui.group-item-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.custom-left-group-button {
    margin-left: 8px;
}

.ladiui.template-grid {
    min-width: calc(100% / 3 - 20px);
    min-height: 290px;
    border-radius: 2px;
    margin-top: 32px;
    position: relative;
    border: 1px solid var(--main-line);
}

.ladiui.opacity-img {
    width: 100%;
    height: 100%;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    opacity: 0.09;
}

@media (max-width: 1371px) {
    .ladiui.template-grid {
        width: calc(100% / 2 - 20px);
        min-height: 290px;
        border-radius: 2px;
        margin-top: 32px;
        position: relative;
    }

    .ladiui.opacity-img {
        min-width: calc(100% / 2 - 20px);
        min-height: 250px;
    }

}

.ladiui.template-grid-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-top: 16px;
}

.ladiui.template-grid-type {
    position: absolute;
    top: 8px;
    left: 8px;
}

.ladiui.template-grid-type-hover {
    position: absolute;
    top: 8px;
    left: 8px;
    display: none;
}

.ladiui.template-grid-button {
    width: 100px;
    height: 36px;
    background-color: var(--main-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
}

.ladiui.group-button-template-grid {
    position: absolute;
    top: 107px;
    left: calc((100% - 216px) / 2);
    display: none;
}

.ladiui.template-grid:hover .ladiui.group-button-template-grid {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type-hover {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type {
    display: none;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    background-color: var(--main-white);
}

.ladiui.template-grid-button {
    cursor: pointer;

}

.ladiui.template-grid-button:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.content-main-item-subtext:hover {
    color: var(--main-primary);
}

.ladiui.template-btn-show-more {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
}

.ladiui.template-btn-show-more {
    margin-bottom: 6px;
}

.ladiui.banner-ads-grid.showcase {
    margin-top: 0px;
}

.ladiui.modal-dialog.showcase {
    width: 824px;
    text-align: center;
    box-shadow: 0px 3px 15px rgba(169, 184, 200, 0.15);
    border-radius: 10px;
}

.ladiui.modal-footer.showcase {
    justify-content: center;
}

.ladiui.show-case-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}

.ladiui.show-case-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 31px 0px 48px 0px;
}

.tox-notifications-container {
    height: 0px !important;
    overflow: hidden !important;
}
body {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    width: 100%;
    font-size: 14px;
    color: #464646;
    --notification-height: 0px;
}

.icon-menu-dashboards {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
}

.icon-menu-affiliates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
}

.icon-menu-customers {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-customer.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-customer.svg) no-repeat 50% 50%;
}

.icon-menu-links {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg) no-repeat 50% 50%;
}

.icon-menu-logout {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logout.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logout.svg) no-repeat 50% 50%;
}

.icon-menu-commissions {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
}

.icon-menu-purchase {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
}

.icon-menu-campaigns {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
}

.icon-menu-invoices {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
}

.icon-menu-request-invoices {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-payment2.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-payment2.svg) no-repeat 50% 50%;
}

.icon-menu-more {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-overflow-menu-horizontal.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-overflow-menu-horizontal.svg) no-repeat 50% 50%;
}

.icon-menu-account-setting {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
}

.icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
}

.icon-menu-sources {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
}

.icon-menu-apps {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-app.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-app.svg) no-repeat 50% 50%;
}

.icon-menu-settings {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-setting.svg) no-repeat 50% 50%;
}

.icon-help-documents {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-helper-document.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-helper-document.svg) no-repeat 50% 50%;
}

th.sort {
    position: relative;
    cursor: pointer;
}

th.sort .sort-icon {
    background: url(https://w.ladicdn.com/ladiui/ladipage/icon-sort-landingpage.svg);
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-bottom: 3px;
    display: inline-block;
}

th.sort.sort-desc .sort-icon {
    background: url(https://w.ladicdn.com/ladiui/ladipage/icon-sort-ascending.svg);
}

th.sort.sort-asc .sort-icon {
    background: url(https://w.ladicdn.com/ladiui/ladipage/icon-sort-descending.svg);
}

.ladiui.page-container a.active {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
    cursor: default;
}

.hidden {
    display: none !important;
}

.w-100 {
    width: 100px !important;
}

.w-175 {
    width: 175px !important;
}

.w-576 {
    width: 576px !important;
}

.w-553 {
    width: 553px !important;
}

.w-44 {
    width: 44px !important;
}

.w-440 {
    width: 440px !important;
}

.w-180 {
    width: 180px !important;
}

.w-220 {
    width: 220px !important;
}

.w-132 {
    width: 132px !important;
}

.w-100-percent {
    width: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.m-12 {
    margin: 12px;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-16 {
    margin-left: 16px;
}

.ml-24 {
    margin-left: 24px;
}

.ml-32 {
    margin-left: 32px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-48 {
    margin-top: 48px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-22 {
    margin-top: 22px !important;
}

.mr-16 {
    margin-right: 16px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-24 {
    margin-right: 24px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.bold-500 {
    font-weight: 500;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.clearfix {
    clear: both;
}

.gap-12 {
    grid-gap: 12px;
    gap: 12px;
}

.gap-16 {
    grid-gap: 16px;
    gap: 16px;
}

.gap-20 {
    grid-gap: 20px;
    gap: 20px;
}

.gap-24 {
    grid-gap: 24px !important;
    gap: 24px !important;
}

.btn-size-34 {
    height: 34px !important;
}

.ladiui.item.bg-form.bg-commissionn-plan {
    background-color: #F1F3F4;
}

.group-input-custom {
    position: relative;
}

.ladiui.btn-group.btn-group-custom {
    position: absolute;
    top: 6px;
    right: 12px;
    background: #F1F3F4;
    max-width: 60px;
    border-radius: 2px;
    text-align: center;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-menu {
    min-width: 0px;
    max-width: 60px;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-toggle {
    max-width: 60px;
    min-width: 34px;
    padding: 0 6px;
    height: 20px;
    line-height: 1;
    background: #F1F3F4;
}

.ladiui.btn-group.btn-group-custom .btn-group {
    max-width: 60px;
    min-width: 34px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-toggle:after {
    margin: 5px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-item {
    padding: 5px;
}

#create-ladishare-comission-plan .ladiui.switch {
    margin: -3px 10px 0px 10px !important;
}

.box-commission-plan {
    margin-top: 32px;
}

.box-commission-plan .card-header {
    font-size: 16px !important;
}

.ladiui.badge {
    border-radius: 2px;
    /* height: 20px; */
    padding: 2px 6px 2px 6px;
    color: #fff;
    font-size: 12px;
}

.ladiui.badge.badge-success {
    background-color: #41B983;
}

.ladiui.badge.badge-warning {
    background-color: #E89D35;
}

.ladiui.badge.badge-mute {
    background-color: #727272;
}

.ladiui.card {
    border: 1px solid #F1F3F4;
}

.ladiui.card-header {
    background-color: #F1F3F4;
    border-bottom-color: #F1F3F4;
}

.box-commission-plan .ladiui.btn-group {
    padding: 0;
}

.box-commission-plan .ladiui.btn-group .ladiui-btn-dropdown.dropdown-toggle {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    margin-right: -8px;
}

.ladiui.table-list-commission-plan-payment {
    margin-top: 10px;
    min-width: 0;
}

.ladiui.table-list-commission-plan-payment tr {
    border-bottom: 1px solid #EAEAEA;
}

.ladiui.table-list-commission-plan-payment tbody tr:last-child {
    border: none;
}

.ladiui.table-list-commission-plan-payment th,
.ladiui.small-label {
    font-size: 14px;
    font-weight: 400;
    color: #727272 !important;
}

.ladiui.table-list-commission-plan-payment tr td,
.ladiui.table-list-commission-plan-payment tr th {
    padding: 12px !important;
}

.ladiui.table-list-commission-plan-payment tr td:first-child {
    padding-left: 0;
}

.ladiui.content-left {
    width: 318px;
    max-width: 318px;
}

.ladiui.content-right {
    width: calc(100% - 318px);
    max-width: calc(100% - 318px);
    min-width: auto;
}

.ladiui .info-affiliate-detail .info-email {
    color: #727272;
}

.ladiui.group-info {
    padding: 12px 0;
}

.ladiui.group-info p {
    padding-top: 8px;
}

.ladiui.group-info p span {
    color: #41B983;
}

.ladiui.divider {
    margin: 12px -24px;
    border: 1px solid #F1F3F4;
}

.color-grey {
    color: #727272;
}

.ladiui.content-main-title.align-item-top,
.align-item-top {
    align-items: flex-start !important;
}

.ladiui.box-statistics {
    border-radius: 2px;
    height: 94px;
    border: 1px solid #F1F3F4;
    width: calc((100% - 72px)/4);
    margin: 12px 0;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: inherit;
}

.ladiui.box-statistics.box-no-padding {
    padding: 0;
}

.ladiui.box-statistics.box-no-heigh {
    height: auto;
}

.ladiui.box-statistics .box-title {
    padding: 12px 24px;
    justify-content: inherit;
    border-bottom: 1px solid #F1F3F4;
}

.ladiui.box-statistics .box-title label {
    font-weight: 500;
}

.ladiui .box-statistics-icon {
    border-radius: 50%;
    background: #F1F3F4;
    width: 48px;
    height: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.ladiui.box-statistics .decrease,
.ladiui.box-statistics .increase {
    font-size: 10px;
}

.ladiui.box-statistics .increase {
    color: #41B983;
}

.ladiui.box-statistics .decrease {
    color: #D94F45;
}

.ladiui.box-statistics.box-bg-grey {
    background-color: #F1F3F4;
    height: 82px;
    width: calc((100% - 48px)/3);
}

.ladiui ul.history-action {
    border-left: 1px dashed #F1F3F4;
    margin-left: 24px;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 12px;
}

.ladiui ul.history-action li {
    margin: 12px 0;
    position: relative;
}

.ladiui ul.history-action li .box-statistics-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: -36px;
    top: calc((100% - 24px)/2);
}

.ladiui ul.history-action li .box-statistics-icon img {
    width: 14px;
    height: 14px;
}

.ladiui ul.history-action li .title {
    font-weight: 500;
    font-size: 14px;
    color: #202020;
    line-height: 20px;
}

.ladiui ul.history-action li .content {
    font-size: 14px;
    line-height: 20px;
}

.ladiui ul.history-action li .content>label {
    font-weight: 500;
}

.ladiui ul.history-action li .content>div {
    font-size: 13px;
    display: flex;
    grid-gap: 4px;
    gap: 4px;
}

.ladiui ul.history-action li .time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #AFAFAF;
}

.ladiui.new-tab-custom {
    position: relative;
    z-index: 0;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background-color: #fff;
    transition: width .3s;
    overflow: hidden;
}

.ladiui.new-tab-custom:hover {
    display: flex;
    width: auto;
}

.ladiui.affiliate-timeline-note {
    margin-top: 40px;
    align-items: baseline;
    justify-content: space-between;
}

.ladiui.affiliate-timeline-note>div {
    width: calc((100% - 24px)/2);
}

.ladiui.affiliate-timeline-note .box {
    border: 1px solid #F1F3F4;
    padding: 16px;
    margin-top: 8px;
    border-radius: 2px;
}

.ladiui.affiliate-timeline-note .box.box-note {
    /* min-height: 150px; */
    white-space: pre-wrap;
}

.ladiui.content-main {
    height: auto;
    min-height: 91.5vh;
}

.ladiui.btn-sm-custom {
    height: 34px;
}

.ladiui.btn-sm-custom .ladiui.dropdown-text {
    margin-right: 20px;
}

.ladiui.table-scroll {
    overflow: auto;
}

.ladiui.table {
    min-width: auto;
}

.ladiui.text-pre {
    white-space: pre;
}

.ladiui.table-padding th,
.ladiui.table-padding td {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.icon-circle {
    background-color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ladiui.table-vertical td {
    color: #202020;
}

.color-1 {
    color: #464646 !important;
}

.color-2 {
    color: #202020 !important;
}

.ladiui.subtitle-2.content-tag-text.active {
    color: #1C00C2 !important;
}

.ladiui.title-text-form-lever2.margin-label {
    margin: 12px 68px 12px 0;
}

.edit-info-affiliate {
    color: #1C00C2;
}

.edit-info-affiliate i {
    background-color: #1C00C2 !important;
}

.filter-condtion-area {
    position: relative;
}

.list-filter-condition {
    display: none;
    position: absolute;
    min-width: 100px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgba(169, 184, 200, 0.15);
    border-radius: 2px;
    padding: 16px;
    margin-top: 5px;
    opacity: 1;
    z-index: 1;

}

.list-filter-condition.show {
    display: block;
}

.list-filter-condition label:not(.search-multi) {
    width: 80px;
}

.list-filter-condition .ladiui.dropdown-toggle {
    min-width: 110px;
}

.list-filter-condition input:not(.size-checkbox) {
    min-width: 120px;
}

.list-filter-condition input.DateInput_input {
    min-width: 0;
}

.list-filter-condition button.select-dropdown,
.list-filter-condition .dropdown-menu .dropdown-item {
    text-transform: lowercase;
}

.list-filter-condition button.select-dropdown .dropdown-text::first-letter,
.list-filter-condition .dropdown-menu .dropdown-item::first-letter {
    text-transform: uppercase;
}

#filter-condtion-area span.ladiui.btn-tag {
    text-transform: lowercase;
}

#filter-condtion-area span.ladiui.btn-tag::first-letter {
    text-transform: uppercase;
}

.ladiui.content-main-tool.align-item-baseline {
    height: auto !important;
    margin-top: 0;
}

.align-item-baseline {
    align-items: baseline !important;
}

.result-filter-condition {
    position: absolute;
}

.result-filter-condition span {
    margin-top: 10px;
    margin-right: 12px;
}

.ladiui.btn-tag {
    background: #F1F3F4;
    border-radius: 2px;
    height: 24px;
    color: #202020;
    font-size: 14px;
    line-height: 24px;
    padding: 0 6px;
    white-space: pre;
}

.cursor-pointer {
    cursor: pointer;
}

.ladiui.form-control {
    border-radius: 2px;
}

.ladiui.general-setting-content label {
    min-width: 130px;
    margin-bottom: 0;
}

.ladiui.general-setting-content {
    max-width: 600px;
    position: relative;
}

.ladiui.note-content {
    position: relative;
}

.ladiui.note-content button.btn-link {
    position: absolute;
    top: -5px;
    right: -5px;
}

.affiliate-setting-content,
.email-notify-setting-content {
    width: 614px;
}

.affiliate-setting-content>div.flex-row,
.email-notify-setting-content>div.flex-row {
    align-items: baseline;
    justify-content: space-between;
}

.affiliate-setting-content>div.flex-row .label-setting label {
    font-size: 16px;
}

.affiliate-setting-content>div.flex-row .label-setting div {
    color: #6D6D6D;
}

.email-notify-setting-content>div.flex-row {
    grid-gap: 12px;
    gap: 12px;
}

.email-notify-setting-content>div label {
    width: 30%;
}

.ladiui.summary {
    background: #F1F3F4;
    border-radius: 2px;
}

.ladiui.summary td {
    padding: 10px 0px 10px 0;
}

.ladiui.summary td span.total-record {
    color: #AFAFAF;
}

#modal-detail-commission {
    color: #202020;
}

#modal-detail-commission .item-detail-commission {
    padding: 10px 0;
    flex: 1 0 31%;
    margin: 5px;
}

#modal-detail-commission .item-detail-commission label {
    color: var(--main-secondary);
    font-weight: 400;
}

#modal-detail-commission .flex-item-detail-commission {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    gap: 24px;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-menu,
.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle,
.ladiui.modal .dropdown-custom .btn-group {
    width: 175px !important;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui.ladi-check-all-action a {
    font-weight: normal;
}

.ladiui.content-main-full {
    padding-top: calc(var(--notification-height) + 65px);
}

.ladiui.content-main-title {
    padding: 20px 0;
}

.ladiui.content-main-tool>.ladiui.btn-group {
    padding: 0;
}

.list-app-integrate {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    gap: 24px;
}

.list-app-integrate.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    gap: 24px;
}

.box-app-integrate {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 8px;
}

.box-app-integrate.box-new {
    border: 1px solid #1C00C2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 140px;
}

.box-app-integrate.box-new img {
    width: 21px;
    margin: 0 10px;
}

.box-app-integrate.box-new button.btn.btn-link {
    font-weight: 500;
    color: #1C00C2;
}

.box-app-integrate-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
}

.box-app-integrate-logo {
    padding-left: 8px;
}

.box-app-integrate-logo img {
    height: 24px;
}

.box-app-integrate-option button.ladiui.btn {
    padding: 0px 4px;
    margin-left: 8px;
}

.box-app-integrate-content-label {
    padding: 8px;
    font-weight: 500;
    color: #3E526A;
}

.box-app-integrate-content-description {
    padding: 8px;
    color: #6E7D8F;
}

.list-app-integrate.grid-2 .box-app-integrate:hover {
    border: 1px solid #1C00C2;
    cursor: pointer;
}

.modal-back {
    position: absolute;
    left: 10px;
}

#modal-add-integrate-app input:read-only,
#modal-setting-integrate-app input:read-only {
    background-color: #fbfbfb;
}

input:disabled {
    background-color: #e8eaeb !important;
}

#modal-detail-invoice .modal-body,
#modal-detail-invoice-request .modal-body {
    color: #202020;
}

#modal-detail-invoice .modal-body .invoice-code,
#modal-detail-invoice-request .modal-body .invoice-code {
    font-size: 12px;
    text-align: center;
}

#modal-detail-invoice .modal-body label,
#modal-detail-invoice-request .modal-body label {
    color: #6D6D6D;
    font-size: 14px;
}

#modal-detail-invoice .modal-body .detail-invoice,
#modal-detail-invoice-request .modal-body .detail-invoice {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px;
    gap: 14px;
    margin-top: 28px;
}

#modal-detail-invoice .modal-body .detail-invoice-total,
#modal-detail-invoice-request .modal-body .detail-invoice-total {
    border: 1px solid #F1F3F4;
    padding: 20px;
}

#modal-detail-invoice .modal-body .detail-invoice-total div,
#modal-detail-invoice-request .modal-body .detail-invoice-total div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

#modal-detail-invoice .modal-body .detail-invoice-total hr,
#modal-detail-invoice-request .modal-body .detail-invoice-total hr {
    margin: 5px auto;
    border: none;
    border-top: 1px solid #F1F3F4;
}

#modal-detail-invoice .modal-body .detail-invoice-total .detail-invoice-total-total div,
#modal-detail-invoice-request .modal-body .detail-invoice-total .detail-invoice-total-total div {
    font-weight: 500;
    font-size: 20px;
}

.detail-invoice-action {
    display: inline-flex;
    margin-top: 14px;
}

.info-invoice {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 14px;
    gap: 14px;
    margin-top: 14px;
}

.info-invoice div div {
    margin-top: 10px;
}

.detail-invoice-affiliate-code,
.detail-invoice-note {
    margin-top: 24px;
}

.detail-invoice-affiliate-name div,
.detail-invoice-affiliate-code div,
.detail-invoice-note div {
    margin-top: 8px;
}

.ladiui.my-account-avata,
.ladiui.avt-text {
    width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
    background: #e7e7e7;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.ladiui.avt-text {
    width: 64px;
    height: 64px;
}

.statistics-top-affiliate {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 14px;
    gap: 14px;
    margin-top: 28px;
}

.statistics-top-affiliate>div {
    border: 1px solid #F1F3F4;
    padding: 8px 0;
}

.statistics-top-affiliate .title {
    border-bottom: 1px solid #F1F3F4;
    padding: 0 12px 8px 12px;
    font-weight: 500;
    font-size: 14px;
}

.statistics-top-affiliate .content {
    padding: 0 12px;
    font-size: 14px;
}

/* #modal-select-platform .modal-dialog {
    width: 400px;
} */

#modal-select-platform .modal-dialog .ladiui.modal-body {
    margin: 0;
    padding: 0;
}

#modal-select-platform .box-app-integrate {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    min-height: 108px;
    align-items: center;
}

#modal-select-platform .box-app-integrate-content label {
    font-weight: 500;
    padding-bottom: 10px;
    display: block;
    color: var(--main-default);
    cursor: pointer;
}

/* .list-platform li {
    display: flex;
    padding: 12px 24px;
    cursor: pointer;
}

.list-platform li .icon-platform {
    margin-right: 24px;
    align-items: center;
    display: flex;
}

.list-platform li .icon-platform img {
    width: 28px;
    height: 28px;
    object-fit: cover;
}

.list-platform li:hover {
    background: #F1F3F4;
    transition: all 0.3s;
}

.list-platform li label {
    color: #202020;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.list-platform li p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
} */

.campaign-detail-title label,
.campaign-detail-options,
.campaign-detail-url {
    width: 50%;
}

.campaign-detail-title label {
    margin-left: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #202020;
}

.campaign-detail-options {
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.campaign-detail-options label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #AFAFAF;
}

.campaign-detail-options div.bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: 8px;
}

.campaign-detail-url {
    margin-top: 16px;
}

.campaign-detail-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    gap: 24px;
    margin-top: 16px;
}

.campaign-detail-content label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
}

.campaign-detail-content .content-left-detail {
    margin-top: 8px;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 5px;
    min-height: 100px;
    padding: 16px;
}

.campaign-detail-content .content-right .label-share {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #AFAFAF;
}

.campaign-detail-content .content-right .number-share {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-left: 16px;
}

.lowercase {
    text-transform: lowercase;
}

.campaign-society-platform .custom-form,
.campaign-society-platform .ladiui.dropdown,
.campaign-society-platform .ladiui.dropdown-menu {
    width: 100% !important;
}

.tox .tox-notification {
    display: none !important
}

.item-campaign {
    padding: 16px 0;
    border-bottom: 1px solid #F1F3F4;
}

.item-campaign:first-child {
    padding-top: 0;
}

.item-campaign:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.ladiui.modal-footer.custom-ldp1 {
    justify-content: end;
}

.reference-no {
    display: block;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.ladiui.col-first {
    width: 35px;
}

.ladiui.dropdown {
    position: relative;
}

#modal-coupon-code .ladiui.dropdown,
#modal-coupon-code.ladiui.modal .btn-group,
#modal-coupon-code.ladiui.modal .btn-group .dropdown-toggle,
#modal-coupon-code.ladiui.modal .btn-group .dropdown-menu {
    width: 100%;
}

#modal-coupon-code input.ladiui.form-control {
    height: 36px;
}

#modal-campaign-share .btn-group,
#modal-campaign-share .btn-group .dropdown-toggle {
    width: inherit;
}

.cursor-copy {
    cursor: copy;
}

.dropdown-setting-custom .btn-group.custom-form {
    padding-bottom: 0;
}

.dropdown-menu.dropdown-right {
    right: 0;
}

.icon-ldp {
    font-family: 'LadiIcons';
}

.icon-ldp.icon-2x {
    font-size: 1.5em;
}

.api-key-action {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 15px;
}

.box-api-key {
    position: relative;
    width: 100%;
}

.box-api-key .icon-ldp-on-access-key {
    position: absolute;
    left: 15px;
    top: 10px;
}

.box-api-key input.form-control {
    padding-left: 40px;
}

.box-api-key .icon-ldp {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
}

.box-app-discount {
    border-radius: 5px;
    background: #F1F3F4;
    padding: 16px;
    margin: 10px 0;
}

.box-app-discount .form-group-custom {
    margin-top: 8px;
}

.aff-dashboard-setting-content,
.affiliate-account-box {
    width: 672px;
}

.aff-dashboard-setting-content .form-group-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.aff-dashboard-setting-content .form-group.flex-start {
    justify-content: flex-start;
}

.aff-dashboard-setting-content .form-group>label {
    width: 172px;
}

.form-group-file-image {
    position: relative;
}

.form-group-file-image input {
    width: 1px;
    height: 1px;
}

.form-group-file-image._logo .img-preview {
    height: 56px;
    width: 160px;
    padding: 6px;
    background: #F1F3F4;
    border-radius: 2px;
}

.form-group-file-image._logo label {
    opacity: 0;
    transition: all 0.3s;
}

.form-group-file-image._logo:hover label {
    opacity: 1;
    transition: all 0.3s;
}

.form-group-file-image._icon {
    display: flex;
    align-items: center;
}

.form-group-file-image._icon .img-preview {
    height: 56px;
    width: 56px;
    background: #F1F3F4;
    border-radius: 2px;
}

.tox-statusbar__branding {
    display: none;
}

table.ladiui.table th {
    height: 40px;
    padding: 12px 6px 12px 6px;
    vertical-align: middle;
}

table.ladiui.table th.col-action {
    min-width: 150px;
}

.highlight-1 {
    text-transform: lowercase;
    vertical-align: baseline;
}

.avartar .ladiui.body-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-history {
    position: relative;
}

.link-to-report {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.upload-img-banner {}

.upload-img-banner input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
}

.upload-img-banner .list-img-banner-preview {
    display: grid;
    grid-gap: 12px;
    gap: 12px;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
}

.upload-img-banner .list-img-banner-preview .img-banner-preview {
    height: 88px;
    border-radius: 2px;
    background-color: #e7e7e7;
    width: 100%;
    position: relative;
}

.upload-img-banner .list-img-banner-preview .img-banner-preview .remove-img-banner-preview {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 5px;
    right: 5px;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-img-banner .list-img-banner-preview .img-banner-preview .remove-img-banner-preview:hover {
    background-color: #f7f7f7;
}

.upload-img-banner .list-img-banner-preview .img-banner-preview>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.upload-img-banner button:hover img {
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

.list-share-banner-img .list-share-banner-img-item {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-img {
    border-radius: 2px;
    width: 220px;
    height: 120px;
    object-fit: cover;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded {
    display: block;
    width: calc(100% - 240px);
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded textarea {
    padding: 8px 32px 8px 12px;
    height: 98px;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    margin-top: 2px;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-download {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    top: 0;
    justify-content: center;
    align-items: center;
    display: none;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-image:hover .list-share-banner-img-item-download {
    display: flex;
}

.summary-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 24px;
    gap: 24px;
    overflow: hidden;
}

.ladiui.setting-content-left {
    z-index: 1;
    background-color: #FFFFFF;
    height: 100%;
    border-right: 1px solid var(--main-line);
}

.info-package-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    gap: 24px;
    padding: 12px;
}

.detail-invoice-print {
    width: 1024px;
    margin: 50px auto;
}

.box-print-button {
    align-items: center;
    display: flex;
    justify-content: center;
}

.detail-invoice-print .box-code {
    display: flex;
    justify-content: end;
    align-items: center;
}

.detail-invoice-print .box-code .code {
    text-transform: uppercase;
    font-weight: 500;
}

.detail-invoice-print .box-info-invoice .invoice-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
}

.detail-invoice-print .box-info-invoice label {
    font-weight: 500;
    padding: 12px 0;
    display: block;
}

.table-invoice-detail {
    border: 1px solid #cacccd;
}

.table-invoice-detail thead {
    background-color: #e7e7e7;
}

.table-invoice-detail thead th,
.table-invoice-detail tbody td {
    padding: 12px;
    border-right: 1px solid #cacccd;
}

.CalendarMonth_table tr td {
    padding: 0 !important;
}

#modal-purchase .time-expired-plan-payment .DateInput_input.DateInput_input_1,
#modal-invoice-selected-affiliate .time-expired-plan-payment .DateInput_input.DateInput_input_1 {
    height: 34px !important;
}

#modal-purchase .time-expired-plan-payment .SingleDatePicker.SingleDatePicker_1,
#modal-invoice-selected-affiliate .time-expired-plan-payment .SingleDatePicker.SingleDatePicker_1 {
    margin-top: 0;
}

#modal-purchase .time-expired-plan-payment .SingleDatePickerInput_clearDate,
#modal-invoice-selected-affiliate .time-expired-plan-payment .SingleDatePickerInput_clearDate {
    width: 28px;
    height: 28px;
    padding: 0;
}

.content-detail-commission-value {
    display: grid;
    grid-gap: 12px;
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
}

.main-title-dashboard {
    display: none;
}

.highcharts-no-tooltip-box {
    width: 650px;
}

.ladiui.modal .btn-group.ladi-dropdown-multi-select,
.ladiui.modal .btn-group.ladi-dropdown-multi-select .ladiui.select-search.dropdown,
.ladiui.modal .btn-group.ladi-dropdown-multi-select .dropdown-menu {
    width: 100%;
}

.ladiui.modal .btn-group.ladi-dropdown-multi-select .dropdown-toggle,
.ladiui.modal .btn-group.ladi-dropdown-multi-select .ladiui.select-search .dropdown-text {
    width: 100%;
    max-width: 100%;
}

.ladiui.modal table.ladiui.table th {
    white-space: pre;
}

@media print {
    .no-print {
        display: none;
    }
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 5%;
    overflow: hidden;
    background-color: #fafafa;
    z-index: 900000080;
    background-size: 100px;
}

.builder-loading-blur {
    background-color: rgba(12, 97, 242, .05);
}

.ladiui.item-package {
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0;
}

.ladiui.text-package-type {
    color: var(--main-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.ladiui.value-package-type.custom {
    color: var(--main-default);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.modal-footer.custom-package-type {
    padding: 35px 0 0;
}

.ladiui.modal-dialog.custom-package-type {
    width: 394px !important;
}

.bg-commissionn-plan .btn-group.custom-form .ladiui.dropdown-toggle::after {
    margin: 12px 14px 10px 16px !important;
}

.package-info {
    padding: 24px;
    border: 1px solid var(--main-line);
    width: 394px;
    margin-top: 36px;
}

.ladiui.account-header-text {
    pointer-events: all;
}

.shop-id-comment {
    font-size: 0.9em;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #666;
}

.ul-selected {
    border-bottom: 1px solid var(--main-line);
}

.ladi-dropdown-multi-select .ladiui.select-search.dropdown .ladiui-btn-tag {
    display: block;
}

.custom-dropdown-text-1 {
    margin-right: 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-option-check li {
    padding: 8px 0;
    display: flex;
    justify-content: left;
    align-items: center;
}

.list-option-check {
    margin-top: 12px;
    margin-left: 24px;
}

.list-option-check li label {
    color: #464646;
    font-size: 14px !important;
}

.list-option-check li label input {
    margin-right: 12px;
}

.ladiui.access.content-main-tool .search-group {
    width: auto;
}

label.ladiui.title-text-form-lever3 {
    margin-bottom: 3px;
    margin-left: 3px;
    font-size: 13px;
}

#create-ladishare-comission-plan .check-switch-2 .ladiui.switch {
    margin-top: 8px !important;
    margin-left: 20px !important;
}

.tooltip .tooltiptext.top::before {
    max-width: 250px;
}

.detail-info-commission .label-info,
.detail-total-commission .label-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.detail-info-commission .label-info-2,
.detail-total-commission .label-info-2 {
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.detail-total-commission {
    border: 1px solid #EEEEEE;
    border-radius: 2px;
    padding: 20px;
    align-items: center;
}

.detail-total-commission>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-total-commission-value {
    font-size: 20px;
    font-weight: 500;
    color: var(--main-default);
}

.detail-total-commission-value-group {
    border-top: 1px solid #EEEEEE;
    margin-top: 10px;
    padding-top: 12px;
}

.ladiui.status-starter {
    width: auto;
}

.btn-change-group-setting {
    display: flex !important;
    align-items: center;
}

.btn-change-group-setting,
.btn-change-group-setting:hover {
    color: var(--main-primary) !important;
}

.btn-change-group-setting .icon-menu-settings {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
    background-size: 16px;
    background-color: var(--main-primary);
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
            mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
    -webkit-mask-size: 16px;
            mask-size: 16px;

}

.SingleDatePickerInput__withBorder {
    border: 1px solid var(--secondary-color) !important;
}

.commission-date-input .DateInput__small {
    width: 100%;
}

.commission-date-input .SingleDatePickerInput__showClearDate {
    height: 32px;
}

.commission-date-input.commission-date-input-size-34 .SingleDatePickerInput__showClearDate {
    height: 34px;
}

.commission-date-input .SingleDatePickerInput__showClearDate {
    padding-right: 0;
    display: flex;
    align-items: center;
}

.commission-date-input .SingleDatePickerInput_clearDate__small {
    background-color: #FFFFFF;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}

.input-ref-register:read-only,
.input-ref-register:read-only:focus {
    border: none;
    background-color: var(--secondary-color);
}

.ladiui.dropdown-toggle.import-data-btn::after {
    content: none;
}

.dropdown-status-affiliate,
.dropdown-status-affiliate .ladiui.btn-group,
.ladiui.modal .dropdown-status-affiliate .btn-group .dropdown-toggle,
.ladiui.modal .dropdown-status-affiliate .btn-group .dropdown-menu {
    width: 100%;
}

.dropdown-status-affiliate .ladiui.btn-group {
    display: block;
}

.ladiui.box-gray {
    padding: 16px;
    background: var(--main-line);
    border-radius: 5px;
    margin-top: 10px;
}

.ladiui.box-white {
    background: var(--main-white);
    padding: 16px;
    border-radius: 5px;
    margin-top: 10px;
}

.ladiui.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui.dropdown-menu {
    border-radius: 2px;
}

/* New style */
.ladiui.table-vertical.main {
    border-bottom: 1px solid var(--secondary-color);
}

.ladiui.table-vertical.main:last-child {
    border-bottom: none;
}

.ladiui.table-vertical td {
    padding: 12px 6px 12px 6px;
}

.d-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    gap: 12px;
}

.d-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    gap: 12px;
}

.d-grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
    gap: 12px;
}

.grid-template-177 {
    grid-template-columns: 177px 177px 177px 177px;
}

.time-expired-plan-payment .SingleDatePicker.SingleDatePicker_1 {
    margin-top: 1px;
}

.time-expired-plan-payment .DateInput.DateInput_1 {
    width: 83%;
}

.time-expired-plan-payment .SingleDatePickerInput_clearDate {
    margin-right: 5px;
}

.time-expired-plan-payment .SingleDatePickerInput__showClearDate {
    padding-right: 0;
}

.time-expired-plan-payment .SingleDatePickerInput_clearDate__hide {
    display: none;
}

.time-expired-plan-payment .SingleDatePickerInput_calendarIcon {
    margin: 0;
    padding: 4px;
}

.time-expired-plan-payment .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
    border: none;
}

.time-expired-plan-payment .DateInput_input.DateInput_input_1 {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 30px;
    color: var(--main-black);
    background-color: var(--main-white);
    background-clip: padding-box;
    /* border: 1px solid var(--main-line); */
    -webkit-appearance: none;
    appearance: none;
    border-radius: 2px;
}

.time-expired-plan-payment .SingleDatePickerInput_clearDate {
    padding: 4px;
    background-color: #fff;
}

.time-expired-plan-payment .SingleDatePickerInput__disabled {
    padding-right: 0 !important;
}

.time-expired-plan-payment .DateInput_input__disabled {
    font-style: normal;
    width: 100%;
}

.time-expired-plan-payment .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
    position: relative;
}

.time-expired-plan-payment .SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
    left: auto !important;
    top: 42px !important;
    right: 0 !important;
}

.time-expired-plan-payment .DateInput_fang.DateInput_fang_1 {
    top: 32px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
}

#create-ladishare-comission-plan .ladiui.dropdown-toggle::after {
    margin: 10px !important
}

#create-ladishare-comission-plan .ladiui.btn-group.btn-group-custom .ladiui.dropdown-toggle:after {
    margin: 5px !important;
}

.table-integrate-app-coupon {
    width: 100%;
}

.table-integrate-app-coupon tr td {
    padding: 4px !important;
}

.dropdown-custom-2 .btn,
.dropdown-custom-2 .dropdown-menu {
    min-width: 0;
    width: 120px !important;
}

#create-ladishare-comission-plan .ladiui.dropdown,
#create-ladishare-comission-plan .ladiui.btn-group,
#create-ladishare-comission-plan .ladiui.item-form.dropdown-custom {
    width: 100% !important;
}

#create-ladishare-comission-plan .dropdown-custom .btn-group .dropdown-menu,
#create-ladishare-comission-plan .dropdown-custom .btn-group .dropdown-toggle,
#create-ladishare-comission-plan .dropdown-custom .btn-group {
    width: 100% !important;
}

#create-ladishare-comission-plan .ladiui.select-search .btn {
    max-width: 100% !important;
}

#modal-setting-change-group .ladiui.switch {
    margin: 0 12px;
}

.ladiui.modal.modal-custom-2 .dropdown-custom .btn-group .dropdown-menu,
.ladiui.modal.modal-custom-2 .dropdown-custom .btn-group .dropdown-toggle,
.ladiui.modal.modal-custom-2 .dropdown-custom .btn-group,
.ladiui.modal.modal-custom-2 .ladiui.btn-group.custom-form-dynamic,
.ladiui.modal.modal-custom-2 .ladiui.btn-group,
.ladiui.modal.modal-custom-2 .ladiui.item-form.dropdown-custom,
.ladiui.modal.modal-custom-2 .ladiui.dropdown {
    width: 100% !important;
}

.get-short-link {
    position: absolute;
    right: 12px;
    top: 8px;
    color: #1C00C2;
}

.input-base-link {
    padding-right: 45px !important;
}

.button-copy-link {
    position: absolute;
    right: 12px;
    top: 8px;
    color: #1C00C2;
}

.input-copy-link {
    padding-right: 45px !important;
}

.img-bg-campaign {
    width: 100%;
    height: 200px;
    object-fit: cover;
    padding: 0.3rem;
}

.calendar-search {
    margin-left: 22px;
}

.more-click {
    display: none;
}

.main-search-condition {
    min-width: calc(100% - 210px);
}

.custom-dropdown-2 .ladiui.dropdown-text {
    margin-right: 10px;
}

.dropdown-310 .ladiui.dropdown-toggle {
    width: 310px;
}

.ladiui.dropdown-toggle::after {
    background: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-down.svg) no-repeat center;
    background-size: contain;
}

.tox-tinymce {
    /* border: 1px solid var(--secondary-color) !important; */
    border-radius: 2px !important;
}

.dropdown-max-height .dropdown-menu {
    max-height: 250px !important;
    overflow-y: auto;
}

ul.list-group-aff li {
    display: inline-block;
    border: 1px solid var(--main-white);
    border-radius: 2px;
    padding: 0 6px !important;
    margin-right: 5px;
    margin-bottom: 5px;
    background: var(--main-white);
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

ul.list-group-aff li.secondary-color {
    border: 1px solid var(--secondary-color);
    background: var(--secondary-color);
}

/* End new style */

/* Publisher CSS------------ */
.publisher-container {
    height: 100vh;
    min-height: 768px;
    background: #F1F3F4;
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    align-items: center;
    justify-content: center;
}

.box-publisher {
    width: 792px;
    background: #FFFFFF;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    gap: 0;
    border-radius: 2px;
    position: relative;
    min-height: 570px;
}

.box-publisher .box-logo {
    position: absolute;
    /* top: -60px; */
    width: 100%;
    text-align: center;
}

.box-publisher .box-publisher-left {
    padding: 40px;
}

.box-publisher .box-publisher-right {
    padding: 40px;
    border-left: 1px solid #F1F3F4;
}

.publisher-footer {
    display: flex;
    position: absolute;
    bottom: -60px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.publisher-footer-item {
    text-align: center;
    border-left: 1px solid #C5C5C5;
    padding: 0 22px;
    color: #000000;
}

.publisher-footer-item img {
    padding-bottom: 5px;
    height: 25px;
    padding-left: 12px;
}

.publisher-footer-item a {
    color: #000000;
}

.publisher-footer-item:first-child {
    border-left: none
}

.publisher-container .box-publisher .box-title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.publisher-container .box-publisher .box-publisher-right .box-title {
    text-align: left;
}

.publisher-container .box-publisher .box-publisher-right .box-sub-title {
    color: #AFAFAF;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.publisher-container .box-publisher .box-description {
    margin-top: 40px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.publisher-container .box-publisher .box-publisher-right .btn {
    width: 100%;
    justify-content: center;
}

.publisher-container .box-publisher .box-publisher-right a.box-forgot-password {
    float: right;
    color: #6D6D6D;
    margin-top: 12px;
    margin-bottom: 21px;
}

.campaign-publisher-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(17%, 1fr));
    grid-gap: 1rem;
    gap: 1rem;
}

.publisher-box-campaign {
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
}

.dashboard-left-publisher {
    width: 66%;
}

.dashboard-right-publisher {
    width: 33%;
}

/* End Publisher CSS----------- */
@media screen and (max-width: 640px) {

    html,
    body,
    input {
        font-size: 13px !important;
        margin: 0;
        padding: 0;
    }

    .ladiui.aside.pc {
        display: none !important;
    }

    .ladiui.aside.mobile {
        display: block !important;
    }

    .ladiui.menu-mobile {
        display: flex;
        width: 100vw;
        position: fixed;
        bottom: 0;
        min-height: 60px;
        border: 1px solid var(--main-line);
        z-index: 1;
        background-color: var(--main-white);
    }

    .ladiui.menu-item {
        padding: 0;
        width: calc(100%/6) !important;
        padding: 0;
        align-items: center;
        justify-content: center;
        display: flex;
        transition: width .3s;
        border-left: 2px solid var(--secondary-color);
    }

    .ladiui.menu-item.active {
        background-color: var(--main-white);
        border-bottom: 2px solid var(--main-primary);
        border-left: none;
    }

    .ladiui.menu-item {
        transition: width .3s;
        width: auto;
        border-left: none;
    }

    .ladiui.main:not(.table-vertical) {
        margin: 0;
        width: 100%;
        overflow: scroll;
        height: calc(100vh - 70px);
    }

    .ladiui.topbar {
        display: none;
    }

    .ladiui.content-main-full {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0;
    }

    .ladiui.wrapper {
        min-width: 0;
    }

    .btn-calendar {
        display: none !important;
    }

    /* .btn-calendar.sevendaysago {
        display: block !important;
    } */

    .filter-calendar-dashboard-publisher {
        justify-content: space-between;
        margin-top: 0 !important;
    }

    .dashboard-statistics-publisher {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 12px !important;
        gap: 12px !important;
        overflow: hidden;
    }

    .dashboard-statistics-publisher>div {
        height: 100px;
        padding: 10px 16px;
    }

    .dashboard-publisher {
        display: block !important;
    }

    .dashboard-right-publisher {
        margin-top: 16px;
        width: 100%;
    }

    .publisher-container {
        display: block;
    }

    .box-publisher {
        display: block;
        width: auto;
    }

    .box-publisher .box-logo {
        position: relative;
        top: auto;
        margin-top: 24px;
    }

    .box-publisher .box-publisher-left,
    .box-publisher .box-publisher-right {
        padding: 24px;
        padding-bottom: 0;
    }

    .publisher-container .box-publisher .box-title {
        margin-bottom: 24px;
    }

    .publisher-container .box-publisher .box-description {
        margin-top: 0;
    }

    .publisher-container .box-publisher .box-publisher-right .box-title {
        margin-bottom: 0;
    }

    .affiliate-account-box {
        width: auto;
    }

    .dashboard-left-publisher {
        width: 100%;
    }

    .DateInput_input__small {
        font-size: 14px !important;
    }

    .DateInput__small {
        /* width: 87px !important; */
    }

    .search-action .showing {
        display: none;
    }

    .ladiui.search-width {
        min-width: 0;
    }

    .ladiui.content-main-tool.align-item-baseline {
        display: block;
    }

    .ladiui.btn-group.custom-btn-list-landingpage {
        margin-left: 0;
    }

    .campaign-publisher-list {
        grid-template-columns: repeat(1, minmax(100%, 1fr));
        grid-gap: 12px !important;
        gap: 12px !important;
    }

    .get-short-link {
        right: 0px;
        top: -25px;
    }

    .list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-img {
        width: 100px;
        height: 100px;
    }

    .list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded {
        width: calc(100% - 110px);
    }

    .list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded textarea {
        height: 83px;
    }

    .input-base-link {
        padding-right: 12px !important;
    }

    .img-bg-campaign {
        height: 150px;
    }

    .link-share-campaign span {
        display: none;
    }


    .summary-box {
        grid-template-columns: repeat(2, 1fr);
    }

    .content-main-title.dashboard-publisher>div {
        display: block;
    }

    .header-main-report {
        display: block !important;
    }

    .header-main-report .main-title {
        padding-bottom: 20px;
    }

    .header-main-report .calendar-search {
        margin-left: 0;
    }

    .content-main-report {
        padding-bottom: 0 !important;
    }

    .content-main-tool-2 {
        position: relative;
        /* top: 66px; */
        /* right: 10px; */
        margin-top: 14px !important;
    }

    .content-main-tool-2 .filter-condtion-area {
        margin-right: 0 !important;
        width: 100%;
    }

    .list-filter-condition {
        right: 0;
    }

    .list-filter-condition.show {
        width: calc(100vw - 27px);
        z-index: 2;
    }

    .content-condition-filter {
        flex-wrap: wrap;
    }

    .content-condition-filter>div,
    .content-condition-filter div.btn-group,
    .content-condition-filter div.dropdown {
        width: 100%;
        margin-right: 0 !important;
        padding: 0 !important;
    }

    .content-condition-filter input[type='text'] {
        width: 100% !important;
    }

    .content-condition-filter>div {
        margin-bottom: 10px;
    }

    .content-condition-filter>div:first-child {
        margin-top: 10px;
    }

    .content-condition-filter .ladiui.select-search .btn {
        max-width: 100%;
    }

    .result-filter-condition {
        position: absolute;
        /* z-index: 1; */
        right: 0;
        width: calc(100vw - 27px);
        overflow: auto;
        background: #fff;
        padding-bottom: 8px;
    }

    li.ladiui.nav-item.more-item {
        width: 100%;
        padding-bottom: 10px;
        margin: 0;
        border-left: 1px solid var(--main-line);
        border-right: 1px solid var(--main-line);
        padding-left: 10px;
        padding-right: 10px;
        display: none;
    }

    li.ladiui.nav-item.more-item-first {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid var(--main-line);
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    li.ladiui.nav-item.more-item-last {
        border-bottom: 1px solid var(--main-line);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .more-click-on {
        border-bottom: 0 !important;
    }

    .more-click-on li.ladiui.nav-item.more-item {
        display: block;
    }

    .more-click {
        display: block;
    }

    .more-click.hidden {
        display: none;
    }

    .btn-export-invoice,
    .btn-request-invoice {
        display: none !important;
    }

    .ladiui.search-group {
        width: 100%;
    }

    .main-filter-condition {
        width: 100%;
    }

    .main-search-condition {
        position: absolute;
        top: 0;
        right: 0;
    }

    .DateRangePicker_picker__directionLeft {
        width: 100%;
        overflow-x: auto;
        border: 1px solid #dbdbdb;
        /* z-index: 3; */
    }

    .DateInput_1 .DateInput_fang {
        top: 39px !important;
    }

    .more-item-menu {
        display: none !important;
    }

    .menu-item-more {
        position: relative;
    }

    .menu-item-more .sub-menu {
        position: absolute;
        bottom: 59px;
        z-index: 2;
        background: #fff;
        border: 1px solid var(--main-line);
        right: -2px;
        width: 200px;
        border-bottom: 0px;
        display: none;
    }

    .menu-item-more:hover .sub-menu {
        display: block;
    }

    .menu-item-more .sub-menu .sub-menu-item {
        padding: 6px 12px;
        border-bottom: 1px solid var(--main-line);
    }

    .menu-item-more .sub-menu .sub-menu-item:last-child {
        border-bottom: 0px;
    }

    .menu-item-more .sub-menu .sub-menu-item .ladiui.menu-text {
        overflow: unset;
        opacity: 1;
        color: var(--main-secondary);
    }

    .ladiui.menu-item:hover i {
        background-color: var(--main-secondary);
    }

    .ladiui.modal .modal-dialog {
        width: 95%;
        min-width: 0;
    }

    .content-detail-commission-value,
    #modal-detail-invoice .modal-body .detail-invoice,
    #modal-detail-invoice-request .modal-body .detail-invoice {
        display: block;
    }

    .content-detail-commission-value .detail-total-commission,
    .detail-invoice-total-content {
        margin-top: 24px;
    }

    .main-title-dashboard {
        display: block;
    }

    .ladiui.nav.commission-tabs,
    .ladiui.nav.tab-detail-commission {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .commission-tabs .nav-item span {
        white-space: nowrap;
    }

    td .bold-500 {
        white-space: nowrap;
    }

    table.ladiui.table th {
        padding: 0 6px;
    }

    table.ladiui.table td {
        padding-left: 6px;
        padding-right: 6px;
    }

    /* table.ladiui.table th.col-first {
        padding: 0;
    } */

    #modal-detail-commission .flex-item-detail-commission,
    .info-invoice {
        display: block;
    }

    .info-invoice>div {
        margin-top: 24px;
    }

    .no-grid-mobile {
        display: block !important;
    }

    .mobile-table-scroll {
        overflow: auto;
        min-height: 250px;
    }
}
.DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ebedf2 !important;
    border-radius: 2px !important;
    height: 36px;
}

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
    font-weight: 500;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: #0b61f2 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder {
    font-size: 13px !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}

.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
    height: 30px;
}

.DateRangePicker .custom-btn-date-panel {
    padding: 0px 22px 11px 22px;
}

.DateRangePicker .custom-btn-date {
    position: relative;
    height: 100%;
    text-align: left;
    background: none;
    border: 1px solid #0b61f2;
    border-radius: 2px;
    color: #0b61f2;
    padding: 4px;
    margin-right: 16px;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 13px;
}

.DateRangePicker .custom-btn-date:active {
    outline: 0;
}

.DateRangePicker .custom-btn-date.selected {
    color: #fff;
    background: #0b61f2;
}

.DayPickerNavigation_button__horizontalDefault,
.DayPicker_transitionContainer,
.DayPicker__withBorder {
    border-radius: 2px;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0;
}

.DateInput_input__small {
    font-size: 14px;
}

.DateRangePickerInput_clearDates {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.custom-btn-date-panel {
    display: flex;
    justify-content: left;
}

.DateRangePicker .custom-btn-date:last-child {
    margin-right: 0;
}
.custom_d-block__3kTdp {
    display: block !important;
}

.custom_p-0__dO4q8 {
    padding: 0 !important;
}

.custom_m-16__1ZvAr {
    margin: 16px !important;
}

.custom_mr-8__1xfpg {
    margin-right: 8px !important;
}

.custom_ml-8__2YC35 {
    margin-left: 8px !important;
}

.custom_mt-8__1970R {
    margin-top: 8px !important;
}

.custom_mt-16__39Ecm {
    margin-top: 16px !important;
}

.custom_bold__QhTtZ {
    font-weight: 500 !important;
}

.custom_rounded__3kkp5 {
    border-radius: 50% !important;
}

.custom_flex__1ep_a {
    display: flex;
}

.custom_flex-auto__3CdQ3 {
    flex: 1 1 auto !important;
}

.custom_no-height__livnS {
    height: auto !important;
}

.custom_avatar__WlLbo {
    display: flex;
}

.custom_avartar-image__1nCxW {
    margin-right: 8px;
}

.custom_avartar-name__1xh6r {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #202020;
}

.custom_account-id__1Ts62 {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #AFAFAF;
    margin: 0 8px;
}

.custom_avartar-time__2giCR {
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.25px;
    color: #AFAFAF;
}

.custom_content__EolQv {
    color: #202020;
    padding: 16px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
}

.custom_tool__2Es3x {
    border-top: 1px solid #F1F3F4;
    color: #AFAFAF;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.custom_banner__2a2if {
    width: 100%;
    /* height: 250px; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    gap: 12px;
}

.custom_list-user__2HmK_ {
    display: flex;
    margin-top: 16px;
}

.custom_avartar-image-mini__BwbY1 {
    width: 16px;
    height: 16px;
    object-fit: cover;
}

.custom_img-instagram__3g8gc {
    width: 100%;
    height: 200px;
    object-fit: contain;
    padding: 12px;
    border: 1px solid var(--main-line);
    border-radius: 4px;
}

.custom_iframe__3gsCy {
    width: 100%;
    min-height: 200px;
}

.custom_embed-code__COgSh {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D6D;
    width: 100%;
}

.custom_copy-link__3ey1T {
    font-weight: normal;
    color: #1C00C2;
    cursor: pointer;
}

.custom_box-statistics-publisher__3zgiX {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    flex: 1 1 auto;
    padding: 16px;
    width: 100%;
}

.custom_box-statistics-publisher-2__1Wgq7 {
    display: flex;
}

.custom_box-content-publisher-title__pbWkD {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
}

.custom_box-content-publisher-name__1_W5- {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    display: flex;
}

.custom_box-content-publisher-number__5G7Kn {
    display: flex;
    color: #41B983;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.custom_box-statistics-publisher-img__apH82 {
    width: 110px;
    display: flex;
    justify-content: center;
}

.custom_box-statistics-publisher-img__apH82 img {
    width: 64px;
    height: 64px;
}

.custom_box-statistics-publisher-detail__2nYhx {
    width: calc(100% - 130px);
}

.custom_box-statistics-publisher-detail-title__auKCD {
    color: #202020;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.custom_box-statistics-publisher-detail-amount__2sErz {
    color: #41B983;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
}

.custom_box-statistics-publisher-detail-purchase__2nDaT {
    display: flex;
    justify-content: space-between;
}

.custom_box-statistics-publisher-detail-purchase-title__3NlZv {
    color: #6D6D6D;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.custom_box-statistics-publisher-detail-purchase-amount__3KgKu {
    color: #202020;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.custom_box-title__19Byy {
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    padding: 16px;
    border-bottom: 1px solid #F1F3F4;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom_img-campaign__SE3ds {
    width: 100px;
    border-radius: 2px;
}

.custom_item-campaign__lB90x {
    padding: 16px 0;
    border-bottom: 1px solid #F1F3F4;
}

.custom_title__Eiszg {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.custom_box-campaign__22-Qm {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    width: 20%;
}

.custom_grid-4__2-5sX {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;
    gap: 24px;
    justify-content: center;
}

.custom_default-align__1LFmI {
    align-items: center;
    justify-content: space-between;
}

.custom_box-embed-code__2VULz {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 12px;
}

.custom_topbar-account__3dPNS {
    justify-content: end;
    min-width: auto !important;
    /* margin-right: 20px; */
}

.custom_r-10__3K4Bt {
    right: 10px !important;
    margin-top: 7px !important;
}

.custom_coupon-payment__kRjJ8 {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 10px;
    gap: 10px;
    align-items: end;
}

.custom_coupon-payment-commission__3XBo2 {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    gap: 10px;
    align-items: end;
}

.custom_comment-coupon-special__20rYw {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.custom_group-coupon-code___n9hH {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    gap: 10px;
}

.custom_badge-secondary__1AHGw {
    background: #ccc;
    border-radius: 4px;
    padding: 2px 5px;
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
}

.custom_group-flex-coupon__25nlN {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}

.custom_icon-upload-1__N2jqZ {
    min-width: 0 !important;
    position: absolute;
    top: 14px;
    left: 40px;
    width: 32px;
    height: 32px;
    background: #1C00C2;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    border-radius: 50%;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.custom_icon-upload-2__3UJ_k {
    min-width: 0 !important;
    position: absolute;
    top: 14px;
    right: 40px;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: #6D6D6D;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.custom_icon-upload-3__WkIJw {
    color: #1C00C2;
    cursor: pointer;
    margin-left: 12px;
}

.custom_img-fit__1Sq-s {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.custom_warrning-account-active__1TkBo {
    background: #E89D35;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
    border-radius: 2px;
    height: 44px;
    padding: 0 15px;
    margin-top: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: left;
}

.custom_warning-account-active-a__jNJtf {
    color: #FFFFFF;
    text-decoration: underline;
}

.custom_text-box-1__v2svv {
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6D6D6D;
}

.custom_text-ellipsis__1wOVS {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.custom_border__3-aWf {
    border: 1px solid var(--main-line);
}

.custom_campaign-publisher-list__385kM {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
}

.custom_box-statistics-publisher-desc-membership-class__Pkgtg span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #566577;
}

.custom_box-statistics-publisher-title-membership-class__3yFYG {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1D5CD3;
}
